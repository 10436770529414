function CustomDate(props) {
    const { value, onChange, isRequired } = props;

    const handleChange = (event) => {
        const newValue = event.target.value;
        onChange(newValue);
    };

    return (
        <div className="flex flex-col gap-1">
            <label htmlFor="id" className="text-xs font-[400]">
                {props.label.toUpperCase()}
            </label>
            <input
                type="date"
                required={isRequired} // Conditionally set required attribute
                className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                id="id"
                value={value}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                onChange={handleChange}
            />
        </div>
    );
}

CustomDate.defaultProps = {
    isRequired: true,
    value: '',
    onChange: () => {},
};

export default CustomDate;
