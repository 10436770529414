// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import { Tooltip } from "@mui/material";
import axios from 'axios';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomBrowse from "../../general-components/CustomBrowse.component";
import CustomInputNumber from "../../general-components/CustomInputNumber.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import PopUp from "../../general-components/PopUp.componenet";
import CustomSelectMulti from "../../general-components/CustomSelectMulti.component";
import CustomizeSelectBar from "../../general-components/CustomizeSelectBar.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import DeliveryTermsAdd from "./DeliveryTermsAdd";
import PaymentsTermsAdd from "./PaymentsTermsAdd";

export default function ManagePOAdd({ onCancel }) {
    let [deliveryTermsAdd, setDeliveryTermsAdd] = useState(false);
    let [paymentsTermsAdd, setPaymentsTermsAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [checkboxAdd, setCheckboxAdd] = useState(false);
    const [poFlag, setPoFlag] = useState(false);
    const [qty, setQty] = useState("");
    const [curVal, setCurVal] = useState("");
    const [poNo, setPoNo] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [parentCustomerRead, setParentCustomerRead] = useState("");
    const [countryNameRead, setCountryNameRead] = useState("");
    const [paymentsTermsData, setPaymentsTermsData] = useState([]); // Sample initial deliveryTermsData
    const [deliveryTermsData, setDeliveryTermsData] = useState([]); // Sample initial deliveryTermsData
    const [customerData, setCustomerData] = useState([]);
    const [portDichargeData, setPortDischargeData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [paperTData, setPaperTData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [typeOfPackageData, setTypeOfPackageData] = useState([]);

    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedPortDischargeId, setSelectedPortDischargeId] = useState("");
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("");
    const [selectedPaperTId, setSelectedPaperTId] = useState([]);
    const [selectedBrandId, setSelectedBrandId] = useState([]);
    const [selectedTypeOfPackageId, setSelectedTypeOfPackageId] = useState([]);
    const [selectedDeliveryTermsId, setSelectedDeliveryTermsId] = useState("");
    const [selectedPaymentsTermsId, setSelectedPaymentsTermsId] = useState("");

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const dataActive = { searchText: "", isActive: true };
    const data = { searchText: "", customerId: 0, leadStatusId: 2, parentCustomerId: 0, isActive: true };

    const fetchData = async (url, setData, errorMsg) => {
        setIsLoading(true);
        try {
            const response = await axios.post(url, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error(errorMsg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPortDischargeList`, setPortDischargeData, "Error fetching Port OF Discharge Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyData, "Error fetching Currency Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaperTypeList`, setPaperTData, "Error fetching Paper Type Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetBrandList`, setBrandData, "Error fetching Brand Data");
        fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetTypeOfPackagingList`, setTypeOfPackageData, "Error fetching Type of Packagining Data");
    }, []);

    const fetchPaymentTearmsData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setPaymentsTermsData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Payments Terms Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchDeliveryTearmsData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetDeliveryTermsList`, dataActive, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                setDeliveryTermsData(response.data.data);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Payments Terms Data");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchListData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/Customer/GetCustomerList`, data, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (response.data.isSuccess && Array.isArray(response.data.data)) {
                const sortedData = response.data.data;
                setCustomerData(sortedData);
            } else {
                console.log("Invalid response format");
            }
        } catch (error) {
            toast.error("Error fetching Customer list");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchListData();
        fetchPaymentTearmsData();
        fetchDeliveryTearmsData();
    }, []);

    useEffect(() => {
        if (selectedCustomerId) {
            const selectedCustomer = customerData.find(c => c.id === selectedCustomerId);
            if (selectedCustomer) {
                setParentCustomerRead(selectedCustomer.parentCustomer);
                setCountryNameRead(selectedCustomer.countryName);
            }
        }
    }, [selectedCustomerId, customerData]);

    const createChangeHandler = (setter) => (selectedOption) => {
        if (selectedOption) {
            setter(selectedOption.value);
        } else {
            setter(null);
        }
    }

    const createChangeHandlerMulti = (setter) => (selectedOption) => {
        setter(selectedOption || []); // Ensure it's always an array
    }

    const handleCustomerChange = createChangeHandler(setSelectedCustomerId);
    const handlePortDischargeChange = createChangeHandler(setSelectedPortDischargeId);
    const handleCurrencyChange = createChangeHandler(setSelectedCurrencyId);
    const handleDeliveryTermsChange = createChangeHandler(setSelectedDeliveryTermsId);
    const handlePaymentsChange = createChangeHandler(setSelectedPaymentsTermsId);

    const handlePaperTChange = createChangeHandlerMulti(setSelectedPaperTId);
    const handleBrandChange = createChangeHandlerMulti(setSelectedBrandId);
    const handleTypeOfPackageChange = createChangeHandlerMulti(setSelectedTypeOfPackageId);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const selectedPaperType = selectedPaperTId.map(select => select.value).join(",");
            const selectedBrand = selectedBrandId.map(select => select.value).join(",");
            // const selectedTypeOfPackage = selectedTypeOfPackageId.map(select => select.value).join(",");
            const selectedTypeOfPackage = (selectedTypeOfPackageId || []).map(select => select.value).join(",");
            const requestBody = {
                customerId: selectedCustomerId,
                pO_PortDischargeId: selectedPortDischargeId || 0,
                pO_IsPOReceived: poFlag,
                pO_PONumber: poNo || "",
                pO_PaymentTermsId: selectedPaymentsTermsId || 0,
                pO_Quantity: qty || 0,
                pO_CurrencyTypeId: selectedCurrencyId || 0,
                pO_CurrencyValue: curVal || 0,
                pO_PaperTypeId: selectedPaperType || "",
                pO_BrandId: selectedBrand || "",
                pO_TypeOfPackagingId: selectedTypeOfPackage || "",
                pO_DeliveryTermsId: selectedDeliveryTermsId || 0,
                pO_IsPOStatusClosed: checkboxAdd,
                pO_POImage: '',
                pO_POOriginalFileName: profileImage.name || "",
                pO_Image_Base64: profileImage.base64 || "",
                isActive: true,
                piI_IsClosed: false,
                // piC_Date: null,
                // piC_Remarks: "",
                piC_IsConfirmed: false,
                piIssuedList: [],
                paymentReceived_Or_LCReceivedDetails: {
                    paymentOrLCReceived: 0,
                    paymentOrLCClosed: false,
                    paymentReceivedDetail: [],
                    lcReceivedDetail: []
                }
            };

            console.log("response-body-->", requestBody);

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                if (responseData.message === "Record is already exists") {
                    toast.error("Duplicate entry is not permissable");
                } else {
                    toast.success("Record Inserted successfully");
                }

                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 1000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing  PO Received operation:", error);
            toast.error("Error performing  PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Add PO Received</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="p-5 mx-auto">
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div className="">
                                <CustomSelect1
                                    label={"Customer Name"}
                                    placeholder={"ENTER"}
                                    options={customerData.map(cData => ({
                                        value: cData.id,
                                        label: cData.customerName
                                    }))}
                                    onChange={handleCustomerChange}
                                ></CustomSelect1>
                            </div>
                            <div>
                                <CustomTextField2 label={"Parent Customer"} placeholder={"Read only"} readOnly={true} value={parentCustomerRead} isRequired={false} />
                            </div>
                            <div>
                                <CustomTextField2 label={"Country"} placeholder={"Read Only"} readOnly={true} value={countryNameRead} isRequired={false} />
                            </div>

                            <div>
                                <CustomSelect1 label={"Port of Discharge"} placeholder={"ENTER"}
                                    options={portDichargeData.map(cData => ({
                                        value: cData.id,
                                        label: cData.portDischarge
                                    }))}
                                    onChange={handlePortDischargeChange}
                                    isRequired={false}
                                />
                            </div>

                            <div className='pt-5'>
                                <CustomCheckBox3
                                    label={"PO Received"}
                                    state={poFlag}
                                    setState={setPoFlag}
                                ></CustomCheckBox3>
                            </div>
                            <div className="">
                                <CustomAlphatext
                                    label={"PO Number"}
                                    placeholder={!poFlag ? "disabled " : "ENTER"}
                                    value={poNo}
                                    isRequired={false}
                                    onChange={setPoNo}
                                    disabled={!poFlag}  // Disable if PO flag is not checked
                                ></CustomAlphatext>
                            </div>
                        </div>
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 mt-2 w-[1000px]">
                            <div className="">
                                <div className="py-1 ">
                                    <div className="flex justify-between">
                                        <label htmlFor="id" className="text-xs font-400 flex items-center ">
                                            Payment Terms
                                        </label>
                                        <Tooltip title="ADD" arrow placement="left">
                                            <span>
                                                <button type="button" onClick={() => setPaymentsTermsAdd(true)}>
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                                        <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>

                                    <CustomizeSelectBar
                                        options={paymentsTermsData.map(cData => ({
                                            value: cData.id,
                                            label: cData.paymentTerms
                                        }))}
                                        onChange={handlePaymentsChange}
                                    />
                                </div>
                            </div>
                            <div className="mt-2">
                                <CustomInputNumber
                                    label={"Qty"}
                                    placeholder={"ENTER"}
                                    value={qty}
                                    onChange={setQty}
                                    isRequired={false}
                                ></CustomInputNumber>
                            </div>
                        </div>
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 mt-3 w-[1000px]">
                            <div className="">
                                <CustomSelect1 label={"Currency Type"}
                                    isRequired={false}
                                    options={currencyData.map(cData => ({
                                        value: cData.id,
                                        label: cData.currencyType
                                    }))}
                                    onChange={handleCurrencyChange}
                                />
                            </div>
                            <div className="">
                                <CustomTextField2
                                    label={"Currency Value"}
                                    placeholder={"ENTER"}
                                    value={curVal}
                                    onChange={setCurVal}
                                    isRequired={false}
                                ></CustomTextField2>
                            </div>

                            <div className="">
                                <CustomSelectMulti label={"Paper Type"}
                                    isRequired={false}
                                    options={paperTData.map(cData => ({
                                        value: cData.id,
                                        label: cData.paperType
                                    }))}
                                    onChange={handlePaperTChange}
                                />
                            </div>
                            <div className="">
                                <CustomSelectMulti label={"Brand"}
                                    isRequired={false}
                                    options={brandData.map(cData => ({
                                        value: cData.id,
                                        label: cData.brand
                                    }))}
                                    onChange={handleBrandChange}
                                />
                            </div>
                            <div className="">
                                <CustomSelectMulti label={"Type of Packaging"}
                                    isRequired={false}
                                    options={typeOfPackageData.map(cData => ({
                                        value: cData.id,
                                        label: cData.typeOfPackaging
                                    }))}
                                    onChange={handleTypeOfPackageChange}
                                />
                            </div>
                            
                            <div className="">
                                <div className="py-1 ">
                                    <div className="flex justify-between ">
                                        <label htmlFor="id" className="text-xs font-400 flex items-center ">
                                            Delivery Terms
                                        </label>
                                        <Tooltip title="ADD" arrow placement="left">
                                            <span>
                                                <button type="button" onClick={() => setDeliveryTermsAdd(true)}>
                                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.0013 2.28564C5.40964 2.28564 1.66797 6.02731 1.66797 10.619C1.66797 15.2106 5.40964 18.9523 10.0013 18.9523C14.593 18.9523 18.3346 15.2106 18.3346 10.619C18.3346 6.02731 14.593 2.28564 10.0013 2.28564Z" fill="#643c94" />
                                                        <path d="M10.6263 11.244H13.3346C13.6763 11.244 13.9596 10.9606 13.9596 10.619C13.9596 10.2773 13.6763 9.99398 13.3346 9.99398H10.6263V7.28564C10.6263 6.94398 10.343 6.66064 10.0013 6.66064C9.65964 6.66064 9.3763 6.94398 9.3763 7.28564V9.99398H6.66797C6.3263 9.99398 6.04297 10.2773 6.04297 10.619C6.04297 10.9606 6.3263 11.244 6.66797 11.244H9.3763V13.9523C9.3763 14.294 9.65964 14.5773 10.0013 14.5773C10.343 14.5773 10.6263 14.294 10.6263 13.9523V11.244Z" fill="white" />
                                                    </svg>
                                                </button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <CustomizeSelectBar
                                        options={deliveryTermsData.map(cData => ({
                                            value: cData.id,
                                            label: cData.deliveryTerms
                                        }))}
                                        onChange={handleDeliveryTermsChange}
                                    />

                                </div>
                            </div>
                            <div className="">
                                <CustomBrowse id="pi-upload-add" label="PO Received Upload" disabled={!poFlag} isRequired={false}
                                    value={profileImage}
                                    onChange={setProfileImage}
                                />
                            </div>
                            <div>
                                <CustomCheckBox2
                                    label={"PO Status closed"}
                                    state={checkboxAdd}
                                    setState={setCheckboxAdd}
                                ></CustomCheckBox2>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center gap-5 pt-3">
                        <div>
                            <CustomButton1 label={"Submit"} className="text-white bg-prp-color" type="submit" />
                        </div>
                        <div onClick={onCancel}>
                            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                        </div>
                    </div>
                </form>
            </div>

            {deliveryTermsAdd && (
                <PopUp>
                    <DeliveryTermsAdd
                        onCancel={() => {
                            setDeliveryTermsAdd(false);
                            fetchDeliveryTearmsData();
                        }}
                    />
                </PopUp>
            )}

            {paymentsTermsAdd && (
                <PopUp>
                    <PaymentsTermsAdd
                        onCancel={() => {
                            setPaymentsTermsAdd(false)
                            fetchPaymentTearmsData();
                        }}
                    />
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    );
}
