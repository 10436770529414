// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewOrderCompletedPending({ selectedValue }) {
    const oC_OrderCompleteDate = selectedValue?.oC_OrderCompleteDate ? new Date(selectedValue.oC_OrderCompleteDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Order Completed Closed Date" value={oC_OrderCompleteDate} />
                    <CustomViewLabel label="Order Completed Closed" value={selectedValue?.oC_IsOrderCompleteClosed} />
                </div>
            </div>
        </>
    )
}

