import React from 'react';
import CustomViewLabel from '../../general-components/CustomViewLabel.component';
import CustomFileViewer from '../../general-components/CustomFileViewer.component';
import CustomButton2 from '../../general-components/CustomButton2.component';

export default function LCReceivedEditInView({ onCancel, record }) {
  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';

    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <>
      <div className="pb-5  bg-white rounded-lg max-w-full">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className="text-l font-weight-[400]">Lc Received Record View</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-2 mx-2 my-4">
          <div className='max-w-full grid grid-cols-3 gap-4 md:grid-cols-2 w-[500px] border border-prp p-4 rounded-lg'>
            <CustomViewLabel label={`Received Date `} value={formatDateTest(record?.receivedDate)} />
            <CustomViewLabel label={`Lc Number`} value={record.lcNumber} />
            <CustomFileViewer fileURL={record.imageURL} fileName={record.originalFileName} label={`LC Received Upload`} />
          </div>
        </div>

        <div className="flex justify-center gap-5">
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>
    </>
  );
}
