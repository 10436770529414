import * as React from "react";
import CustomViewLabel from "../../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../../general-components/CustomFileViewer.component";

export default function ViewPiConfirmAccept({ selectedValue }) {
    const piC_IsConfirmed = selectedValue?.piC_IsConfirmed ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 pb-4 w-[1000px]">
                    {/* Check if selectedValue and piConfirmationList are defined and have items */}
                    {selectedValue && selectedValue.piConfirmationList && selectedValue.piConfirmationList.length > 0 ? (
                        selectedValue.piConfirmationList.map((item, index) => (
                            <React.Fragment key={index}>
                                <CustomViewLabel label={`Issued date ${index + 1}`} value={formatDateTest(item.piIssueDate)} />
                                <CustomViewLabel label={`PI Number ${index + 1}`} value={item.piNumber} />
                                <CustomFileViewer fileURL={item.piImageURL} fileName={item.piOriginalFileName ?? ''} label={`PI Confirmation Upload ${index + 1}`} />
                                <CustomViewLabel label={`Status ${index + 1}`} value={item.statusName} />
                                <CustomViewLabel label={`Remark ${index + 1}`} value={item.remark} />
                                <CustomViewLabel label={`Created Date  ${index + 1}`} value={formatDateTest(item.createdDate)} />
                            </React.Fragment>
                        ))
                    ) : (<p>No PI Confirmation Data Available</p>
                    )}
                </div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="PI Confirmed Closed " value={piC_IsConfirmed} />
                    <CustomViewLabel label="PI Confirmed Closed Date " value={formatDateTest(selectedValue?.piC_CloseDate)} />
                </div>
            </div>
        </>
    );
}
