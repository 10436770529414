import * as React from "react";
import { useState, useEffect } from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import ManagePOView from "../ManagePO/ManagePOView";
import CustomInputNumber from '../../general-components/CustomInputNumber.component';
import CustomBrowse from "../../general-components/CustomBrowse.component";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { IoCheckmarkCircle } from "react-icons/io5";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from "../../general-components/CustomButton2.component";
import { IoRemoveCircle } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import PopUp from "../../general-components/PopUp.componenet";
import Visiblepopup from "./Visiblepopup";
import PopupVisible from "./PopupVisible";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import axios from 'axios';
import CustomMultitext from "../../general-components/CustomMultitext.component";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Tooltip } from "@mui/material";


export default function ManagePOIssuedEdit({
    selectedValue,
    onCancel
}) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(1);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [visiblePopup, setVisiblePopup] = useState(false)
    const [selectedLogList, setSelectedLogList] = useState([]);
    const [piCheckBox, setPiCheckBox] = useState(false);
    const [piIssuedLogList, setPiIssuedLogList] = useState([]);
    const [updatePopup, setUpdatePopup] = useState(false);

    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if (selectedValue && selectedValue.piIssuedList) {
            const formattedRows = selectedValue.piIssuedList.map((item) => ({
                id: item.id,
                piDate: formatDateForInput(item.piIssueDate),
                piNo: item.piNumber,
                attach: { base64: item.piImageURL, name: item.piOriginalFileName },
                initialAttach: { base64: item.piImageURL, name: item.piOriginalFileName },
                statusId: item.statusId || 1,// Set default statusId to 1 if not already present

                piImgName: item.piOriginalFileName,
                piImgUrl: item.piImageURL
            }));
            setRows(formattedRows);
        }
        if (selectedValue) {
            setPiCheckBox(selectedValue?.piI_IsClosed)
            // Safely map and flatten piIssuedLogList if it exists
            const piLogs = selectedValue?.piIssuedList?.flatMap(item => item?.piIssuedLogList || []);
            setPiIssuedLogList(piLogs);
            setSelectedLogList(piLogs);
        }
    }, [selectedValue]);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    const handleAddRow = () => {
        const newRow = {
            id: 0,
            piDate: '',
            piNo: '',
            attach: { base64: '', name: '' },
            initialAttach: { base64: '', name: '' },
            statusId: 1
        };
        setRows([...rows, newRow]);
    };

    const handleRemoveRow = (rowIndex) => {
        const updatedRows = rows.filter((row, index) => index !== rowIndex);
        setRows(updatedRows);
    };

    const handleRowChange = (index, field, value) => {
        const updatedRows = [...rows];
        // updatedRows[index] = { ...updatedRows[index], [field]: value };
        updatedRows[index] = {
            ...updatedRows[index],
            [field]: value,
            statusId: updatedRows[index].statusId === 3 ? 1 : updatedRows[index].statusId
        };
        setRows(updatedRows);
    };

    const token = localStorage.getItem('DIYAN_IE_authToken');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                piIssuedList: rows.map((row) => ({
                    id: row.id !== 0 ? row.id : 0,
                    piIssueDate: row.piDate,
                    piNumber: row.piNo || "",
                    piImage: "",
                    piImage_Base64: row.attach.base64 === row.initialAttach.base64 ? "" : row.attach.base64,
                    piOriginalFileName: row.attach.name === row.initialAttach.name ? "" : row.attach.name,
                    remark: "",
                    statusId: row.statusId
                })),
                piI_IsClosed: piCheckBox
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    // const handleVisibleOpen = (index) => {
    //     // Ensure piIssuedLogList is not undefined or null before setting
    //     setSelectedLogList(piIssuedLogList || []);
    //     setVisiblePopup(true);
    // }

    const handleVisibleOpen = (index) => {
        // Extract the specific log list for the selected record
        const selectedRecordLogs = selectedValue.piIssuedList[index]?.piIssuedLogList || [];
        setSelectedLogList(selectedRecordLogs);
        setVisiblePopup(true);
    }

    const handleDeleteRecord = async (index, id) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePIIssued?Id=${id}`,
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                toast.success('Record deleted successfully');
                // Remove the row from the UI
                const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
                setRows(updatedRows);
            } else {
                console.error('Failed to delete the record:', response.statusText);
                toast.error('Failed to delete the record');
            }
        } catch (error) {
            console.error('Error deleting the record:', error);
            toast.error('Error deleting the record');
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit PI Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO received"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView selectedValue={selectedValue} />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <div className="max-w-full w-[1000px]">
                            {rows.map((row, index) => (
                                <div key={index} className="max-w-full flex items-center gap-5 w-[1000px] mt-5">
                                    <div className="w-[25%] max-w-[25%]">
                                        <div className="flex flex-col gap-1">
                                            <label htmlFor="date" className="text-xs font-[400]">
                                                PI Issued
                                            </label>
                                            <input
                                                type="date"
                                                required
                                                className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                                id={`pi-issued-${index}`}
                                                value={row.piDate}
                                                onChange={(e) => handleRowChange(index, 'piDate', e.target.value)}
                                                disabled={row.statusId === 2}  // Disable if statusId is 2
                                            />
                                        </div>
                                    </div>
                                    <div className="w-[25%] max-w-[25%]">
                                        <CustomMultitext
                                            label="PI Number"
                                            placeholder="Enter"
                                            value={row.piNo}
                                            onChange={(value) => handleRowChange(index, 'piNo', value)}
                                            disabled={row.statusId === 2}  // Disable if statusId is 2
                                        />
                                    </div>
                                    <div className="w-[30%] max-w-[30%]">
                                        <div>
                                            {index < selectedValue.piIssuedList.length ? (
                                                <CustomFileUpload
                                                    id={`poissuededit-edit-${index}`}
                                                    label="PI Upload"
                                                    value={row.attach}
                                                    onChange={(value) => handleRowChange(index, 'attach', value)}
                                                    initialFileName={row.piImgName}
                                                    initialFileUrl={row.piImgUrl}
                                                    disabled={row.statusId === 2}  // Disable if statusId is 2
                                                />
                                            ) : (
                                                <CustomBrowse
                                                    label="PI Upload"
                                                    id={`poissuededit-edit-${index}`}
                                                    value={row.attach}
                                                    onChange={(value) => handleRowChange(index, 'attach', value)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[20%] max-w-[20%]">
                                        <div className="flex items-center justify-between ">
                                            <div>
                                                {index < selectedValue.piIssuedList.length ? (
                                                    <div className="pt-4 flex gap-5 items-center justify-center">
                                                        {row.statusId === 1 ? (
                                                            <p className="text-xs border border-yellow-500 modal-btn-comman text-yellow-500">Pending</p>
                                                        ) : row.statusId === 2 ? (
                                                            <IoCheckmarkCircle className="h-9 w-9 text-green-500" />
                                                        ) : row.statusId === 3 ? (
                                                            <IoRemoveCircle className="h-10 w-9 text-red-500" />
                                                        ) : null}

                                                        {row.statusId === 2 || row.statusId === 3 ?
                                                            <Tooltip title="OPEN" arrow placement="bottom">
                                                                <span>
                                                                    <button onClick={() => handleVisibleOpen(index)} className="">
                                                                        <IoMdEye className="h-10 w-9 txt-prp-color" />
                                                                    </button>
                                                                </span>
                                                            </Tooltip> : ""
                                                        }

                                                    </div>
                                                ) : (
                                                    null
                                                )}
                                            </div>
                                            <div>
                                                <div className="flex">
                                                    {index < selectedValue.piIssuedList.length ? (
                                                        <Tooltip title="DELETE" arrow placement="bottom">
                                                            <span>
                                                                <button onClick={() => handleDeleteRecord(index, row.id)} className="pt-4">
                                                                    <RiDeleteBin6Line className="h-7 w-7 text-red-500" />
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="DELETE" arrow placement="bottom">
                                                            <span>
                                                                <button onClick={() => handleRemoveRow(index)} className="pt-4">
                                                                    <CiSquareRemove className="h-10 w-9 txt-prp-color" />
                                                                </button>
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))}
                            <div className="flex mt-5 w-[10%] max-w-[10%]">
                                <Tooltip title="ADD" arrow placement="bottom">
                                    <span>
                                        <button onClick={handleAddRow}>
                                            <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                                        </button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="mt-5">
                            <CustomCheckBox3
                                label={"PI Closed"}
                                state={piCheckBox}
                                setState={setPiCheckBox}
                            />
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>


            {visiblePopup && (
                <PopUp>
                    <PopupVisible
                        piIssuedLogList={selectedLogList}
                        onCancel={() => setVisiblePopup(false)}
                    />
                </PopUp>
            )}

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}

            <ToastContainer autoClose={2000} />
        </>
    );
}