import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';

import CustomDate from '../../general-components/CustomDate.component';
import CustomBrowse from '../../general-components/CustomBrowse.component';
import CustomButton1 from '../../general-components/CustomButton1.component';
import CustomFileUpload from "../../general-components/CustomFileUpload.component";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomButton2 from '../../general-components/CustomButton2.component';
import CustomTextField2 from '../../general-components/CustomTextField2.component';
import CustomMultitext from '../../general-components/CustomMultitext.component';

export default function LCReceivedEditInEdit({ onCancel, record, onUpdate, selectedValue }) {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [date, setDate] = useState("");
    const [attach, setAttach] = useState("");
    const [lcNumber, setLcNumber] = useState("");

    useEffect(() => {
        // Log the record object to inspect its contents
        console.log("Record:", record);

        // Log the image-related fields specifically
        console.log("Image URL:", record?.imageURL);
        console.log("Original File Name:", record?.originalFileName);

        // Set the initial date in YYYY-MM-DD format
        if (record && record.receivedDate) {
            setDate(record.receivedDate.split('T')[0]); // Extract the date part
        }
        if (record) {
            setLcNumber(record.lcNumber); // Set LC Number
        }
    }, [record]);

    const parseDate = (formattedDate) => {
        const [year, month, day] = formattedDate.split('-');
        return new Date(`${year}-${month}-${day}`).toISOString();
    };

    const handleDateChange = (e) => {
        const formattedDate = e.target.value;
        setDate(formattedDate);
        // Convert the date back to ISO format for saving/updating purposes if needed
        const isoDate = parseDate(formattedDate);
        console.log('ISO Date:', isoDate);
    };

    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                paymentReceived_Or_LCReceivedDetails: {
                    paymentOrLCReceived: 2,
                    paymentOrLCClosed: selectedValue.plR_IsPaymentOrLCClosed,
                    paymentReceivedDetail: [],
                    lcReceivedDetail: [
                        {
                            id: record.id,
                            receivedDate: date,
                            lcNumber: lcNumber,
                            imageName: "",
                            imageName: attach.base64 ? attach.base64 : "",
                            originalFileName: attach.name ? attach.name : ""
                        },
                    ]
                }
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                onUpdate(requestBody); // Invoke the callback to update the parent state
                onCancel();
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-5  bg-white rounded-lg max-w-full">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className="text-l font-weight-[400]">Lc Received Record Edit</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-2 mx-2 my-4">
                    <div className='max-w-full grid grid-cols-3 gap-4 md:grid-cols-2 w-[650px] border border-prp p-5 rounded-lg'>
                        <div className="">
                            <div className="flex flex-col gap-1">
                                <label htmlFor="id" className="text-xs font-[400]">
                                    LC Received
                                </label>
                                <input
                                    type="date" // Conditionally set required attribute
                                    className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                    id="id"
                                    value={date}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                        <div className="">
                            <CustomMultitext
                                label="LC Number"
                                value={lcNumber}
                                placeholder="Enter"
                                isRequired={false}
                                onChange={setLcNumber}
                            />
                        </div>
                        <div className="">
                            <CustomFileUpload
                                id="pi-upload-add-v1"
                                label="LC Received Upload"
                                value={attach}
                                onChange={setAttach}
                                initialFileName={record?.originalFileName || ''}
                                initialFileUrl={record?.imageURL || ''}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex justify-center gap-5">
                    <div onClick={handleSubmit}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={2000} />
        </>
    );
}
