// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";
import SubAccodion from "../../general-components/SubAccodion";

export default function ViewPaymentLCReceived({ selectedValue }) {
    const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';
    const [imageError, setImageError] = useState({});

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleString('en-US', options);
    };

    return (
        <>
            <SubAccodion title={"Payment Received"}            >
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                        selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                            <React.Fragment key={index}>
                                <CustomViewLabel label="Invoice Number" value={item.invoiceNumber} />
                                <CustomViewLabel label="Payment Terms" value={item.paymentTerms} />
                                <CustomViewLabel label="Payment Received" value={item.paymentReceived} />
                                <CustomViewLabel label="Currency Type" value={item.currencyType} />
                                <CustomViewLabel label="Amount" value={item.amount} />
                                <CustomViewLabel label="Payment Received Date" value={formatDateTest(item.paymentReceivedDate)} />
                            </React.Fragment>
                        ))
                    ) : (
                        <div className="text-xs ">No payment details available.</div>
                    )}
                </div>
            </SubAccodion>

            <SubAccodion title={"LC Received"}            >
                <div className=" max-w-full w-[1000px]">
                    {selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0 ? (
                        selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map((item, index) => (
                            <React.Fragment key={index}>
                                <div className="flex items-center justify-between w-full">
                                    <div className="w-[25%]">
                                        <CustomViewLabel label={`Received Date ${index + 1}`} value={formatDateTest(item.receivedDate)} />
                                    </div>
                                    <div className="w-[25%]">
                                        <CustomViewLabel label={`LC Number ${index + 1}`} value={item.lcNumber} />
                                    </div>
                                    <div className="w-[50%]">
                                        {item?.imageURL && !imageError[index] ? (
                                            <CustomFileViewer fileURL={item.imageURL} fileName={item.originalFileName ?? ''} label={`LC Received Received Upload ${index + 1}`} />
                                        ) : (
                                            <>
                                                <div className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center flex items-center justify-center">
                                                    No image
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>

                            </React.Fragment>
                        ))
                    ) : (
                        <div className="text-xs ">No Lc received details available.</div>
                    )}
                </div>
            </SubAccodion>

            <CustomViewLabel label="Payment / Lc Received Closed" value={plR_IsPaymentOrLCClosed} />
        </>
    )
}
