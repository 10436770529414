// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewFinalAmountToPay({ selectedValue }) {
    const faP_FinalAmountToPayClosedDate = selectedValue?.faP_FinalAmountToPayClosedDate ? new Date(selectedValue.faP_FinalAmountToPayClosedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Final Amount To Pay Date" value={faP_FinalAmountToPayClosedDate} />
                    <CustomViewLabel label="Final Amount To Pay Close" value={selectedValue?.faP_IsFinalAmountToPayClose} />
                </div>
            </div>
        </>
    )
}
