// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewOrderUnderProcess({ selectedValue }) {
    const ouP_OrderUnderProcessDate = selectedValue?.ouP_OrderUnderProcessDate ? new Date(selectedValue.ouP_OrderUnderProcessDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Tentative date of Loading" value={ouP_OrderUnderProcessDate} />
                    <CustomViewLabel label="Order Under Process Close" value={selectedValue?.ouP_IsOrderUnderProcess} />
                </div>
            </div>
        </>
    )
}
