// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";


export default function ViewInvoiceGenerated({ selectedValue }) {
    const iN_IsInvoiceGenerateClose = selectedValue?.iN_IsInvoiceGenerateClose ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const iN_InvoiceGenerateClosedDate = formatDateTest(selectedValue?.iN_InvoiceGenerateClosedDate);
    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">

                    {/* Mapping through containersUnderLoadingList array to display each item */}
                    {selectedValue?.invoiceList?.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label={`Invoice Number ${index + 1}`} value={item.invoiceNumber} />
                            <CustomFileViewer fileURL={item.invoiceImageURL} fileName={item.invoiceOriginalFileName ?? ''} label={`Invoice Generated Upload`} />
                        </React.Fragment>
                    ))}
                </div>

                <div className="max-w-full grid grid-cols-2 mt-4 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Invoice Generate Closed" value={iN_IsInvoiceGenerateClose} />
                    <CustomViewLabel label="Invoice Generate Closed Date" value={iN_InvoiceGenerateClosedDate} />
                </div>
            </div>
        </>
    )
}
