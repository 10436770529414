
// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import Accordion from "../../general-components/Accodion";
import EditBookingIssue from "./EditBookingIssue";
import CustomButton2 from "../../general-components/CustomButton2.component";

import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import { useEffect } from "react";
import CustomBrowse from "../../general-components/CustomBrowse.component";
import CustomFileUpload from "../../general-components/CustomFileUpload.component";
import PopUp from "../../general-components/PopUp.componenet";

export default function ManageBookingIssueEdit({ onCancel, selectedValue }) {
    const [openAccordionIndex, setOpenAccordionIndex] = useState(6);
    const [isLoading, setIsLoading] = useState(false);
    const [etdDate, setEtdDate] = useState("");
    const [etaDate, setEtaDate] = useState("")
    const [docUpload, setDocUpload] = useState("");
    const [bookingIssuedClose, setBookingIssuedClose] = useState(false)
    const [updatePopup, setUpdatePopup] = useState(false);

    const [fileName, setFileName] = useState("");
    const [fileOriginalName, setFileOriginalName] = useState("");
    const [fileUrl, setFileUrl] = useState("");

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    useEffect(() => {
        // Set default date to current date
        const currentDate = new Date().toISOString().split("T")[0];
        setEtdDate(currentDate);
        setEtaDate(currentDate);
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setBookingIssuedClose(selectedValue?.bI_IsBookingIssueAccepted);

            // Check if bI_ETD exists and is not null before splitting
            if (selectedValue?.bI_ETD) {
                setEtdDate(selectedValue.bI_ETD.split("T")[0]);
            }

            // Check if bI_ETA exists and is not null before splitting
            if (selectedValue?.bI_ETA) {
                setEtaDate(selectedValue.bI_ETA.split("T")[0]);
            }

            setFileName(selectedValue?.bI_Image);
            setFileOriginalName(selectedValue?.bI_OriginalFileName);
            setFileUrl(selectedValue?.bI_ImageURL);
        }
    }, [selectedValue]);


    const token = localStorage.getItem('DIYAN_IE_authToken');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const requestBody = {
                id: selectedValue.id,
                customerId: selectedValue.customerId || 0,
                biD_BIDraftComment: "",

                bI_IsBookingIssueAccepted: bookingIssuedClose || false,
                // bI_BookingIssueAcceptedDate: bIssuedDate || null,
                bI_ETD: etdDate || null,
                bI_ETA: etaDate || null,
                bI_OriginalFileName: docUpload.name || "",
                bI_Image_Base64: docUpload.base64 || ""
            };

            console.log("testing--->", requestBody);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestBody),
            });

            const responseData = await response.json();
            if (responseData.isSuccess) {
                toast.success("Record Updated successfully");
                setUpdatePopup(false);
                setTimeout(() => {
                    // window.location.reload(); // Reload the page or fetch updated list
                    onCancel();
                }, 2000);
            } else {
                console.error("Record operation failed", responseData.message);
                toast.error(responseData.message || "Record operation failed");
            }
        } catch (error) {
            console.error("Error performing PO Received operation:", error);
            toast.error("Error performing PO Received operation");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">Edit Booking Issued</h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
                    <Accordion title={"PO recieved"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        {/* <EditBookingIssue /> */}
                        <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="dateetd" className="text-xs font-[400]">
                                        ETD
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={etdDate}
                                        onChange={(e) => setEtdDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-1">
                                    <label htmlFor="dateeta" className="text-xs font-[400]">
                                        ETA
                                    </label>
                                    <input
                                        type="date"
                                        required
                                        className="p-2 border rounded grow min-w-[12rem] text-xs placeholder:text-xs bg-white"
                                        value={etaDate}
                                        onChange={(e) => setEtaDate(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div>
                                <CustomFileUpload
                                    id="BookingIssueUpload123dfgfd"
                                    label="Booking Issue Upload"
                                    isRequired={false}
                                    value={docUpload}
                                    onChange={setDocUpload}
                                    initialFileName={fileOriginalName}
                                    initialFileUrl={fileUrl}
                                />
                            </div>
                            <div className="">
                                <CustomCheckBox2
                                    label={"Booking Issued Close"}
                                    state={bookingIssuedClose}
                                    setState={setBookingIssuedClose}
                                ></CustomCheckBox2>
                            </div>
                        </div>
                    </Accordion>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={() => setUpdatePopup(true)}>
                        <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                    </div>
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={1000} />

            {updatePopup && (
                <PopUp>
                    <div className="pb-2 bg-white border rounded-lg">
                        <div className="flex flex-col p-4">
                            <p className="pb-5 text-sm">Are you sure you want to update ?</p>

                            <div className="flex justify-end gap-2">
                                <div onClick={handleSubmit}>
                                    <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                                </div>
                                <div onClick={() => setUpdatePopup(false)}>
                                    <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUp>
            )}
        </>
    )
}
