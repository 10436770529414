// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewPaymentReceived({ selectedValue }) {
    const pR_PaymentReceivedDate = selectedValue?.pR_PaymentReceivedDate ? new Date(selectedValue.pR_PaymentReceivedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Payment Received Date" value={pR_PaymentReceivedDate} />
                    <CustomViewLabel label="Payment Received Close" value={selectedValue?.pR_IsPaymentReceived} />
                </div>
            </div>
        </>
    )
}


