// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";
import CustomButton4 from "../../general-components/CustomButton4.component";

import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

import PaymentReceivedEditInView from "./PaymentReceivedEditInView";
import PaymentReceivedEditInEdit from "./PaymentReceivedEditInEdit";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PaymentReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {

  const [openAccordionIndex, setOpenAccordionIndex] = useState(3);
  const [iNo, setINo] = useState("");
  const [amt, setAmt] = useState("");
  const [date, setDate] = useState("");
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentTermsData, setPaymentsTermsData] = useState([]);
  const [currencyTypeData, setCurrencyTypeData] = useState([]);
  const [paymentRecivedData, setPaymentRecivedData] = useState([]);
  const [paymentTermsId, setPaymentsTermsId] = useState("");
  const [currencyTypeId, setCurrencyTypeId] = useState("");
  const [paymentRecivedId, setPaymentRecivedId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);

  const [recordView, setRecordView] = useState("");
  const [recordEdit, setRecordEdit] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [prlClose, setPrlClose] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const token = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive = { searchText: "", isActive: true };

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setPrlClose(selectedValue.plR_IsPaymentOrLCClosed)
    }
  }, [selectedValue]);

  const fetchData = async (url, setData, errorMsg) => {
    setIsLoading(true);
    try {
      const response = await axios.post(url, dataActive, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData, "Error fetching Payment Terms Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData, "Error fetching Currency Type Data");
    fetchData(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData, "Error fetching Payment Received Data");
  }, []);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };

  const handleAddRow = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const newRow = { iNo: '', amt: '', date: currentDate, paymentTermsId: "", currencyTypeId: "", paymentRecivedId: "" };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Generate rows with dynamic IDs
      const rowsWithDynamicIds = rows.map((row) => ({
        id: 0,
        invoiceNumber: row.iNo || "",
        paymentTermsId: row.paymentTermsId || 0,
        paymentReceivedDate: row.date || "",
        currencyTypeId: row.currencyTypeId || 0,
        amount: row.amt || 0,
        paymentReceivedId: row.paymentRecivedId || 0
      }));

      // Construct paymentReceivedDetail only if there's at least one field with a non-default value
      const paymentReceivedDetail = [];
      if (iNo || paymentTermsId || currencyTypeId || amt || paymentRecivedId) {
        paymentReceivedDetail.push({
          id: 0,
          invoiceNumber: iNo || "",
          paymentTermsId: paymentTermsId || 0,
          paymentReceivedDate: date || "",
          currencyTypeId: currencyTypeId || 0,
          amount: amt || 0,
          paymentReceivedId: paymentRecivedId || 0
        });
      }

      // Add rows if any exist
      if (rowsWithDynamicIds.length > 0) {
        paymentReceivedDetail.push(...rowsWithDynamicIds);
      }

      // Construct the request body
      const requestBody = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlClose,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: []  // Only include if there are actual details
        }
      };

      console.log("Request Body: ", requestBody);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup(false);
        setTimeout(() => {
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }

    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading(false);
    }
  };


  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleViewButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordView(true);
  };

  const handleEditButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordEdit(true);
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const currentRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex, endRecordIndex) || [];

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleDeleteRecord = async (id) => {
    console.log(`Attempting to delete record with ID: ${id}`); // Log the ID being passed

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePaymentReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Record with ID ${id} deleted successfully`); // Log successful deletion
        toast.success('Record deleted successfully');

        setSelectedValue(prevState => ({
          ...prevState,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevState.paymentReceived_Or_LCReceivedDetail,
            paymentReceivedDetail: prevState.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.filter(record => record.id !== id)
          }
        }));
      } else {
        console.error(`Failed to delete the record with ID ${id}:`, response.statusText); // Log failure details
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      console.error(`Error deleting the record with ID ${id}:`, error); // Log error details
      toast.error('Error deleting the record');
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Payment Received / LC Received</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex === 0}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex === 1}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex === 2}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Payment Received / LC Received"}
            isOpen={openAccordionIndex === 3}
            onClick={() => handleAccordionClick(3)}
          >
            {/* <EditPayment /> */}
            <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
              <div>
                <CustomAlphatext
                  label={"Invoice Number"}
                  placeholder={"Enter"}
                  value={iNo}
                  onChange={setINo}
                />
              </div>
              <div>
                <CustomSelect1 label={"Payment Terms"}
                  options={paymentTermsData.map(cData => ({
                    value: cData.id,
                    label: cData.paymentTerms
                  }))}
                  onChange={(option) => setPaymentsTermsId(option.value)}
                />
              </div>
              <div className="">
                <CustomDate label={"Payment received"} placeholder={"Enter"}
                  value={date}
                  onChange={setDate}
                />
              </div>
              <div className="">
                <CustomSelect1 label={"Currency Type"}
                  options={currencyTypeData.map(cData => ({
                    value: cData.id,
                    label: cData.currencyType
                  }))}
                  onChange={(option) => setCurrencyTypeId(option.value)}
                />
              </div>
              <div className="">
                <CustomInputNumber label={"Amount"} placeholder={"ENTER"}
                  value={amt}
                  onChange={setAmt}
                />
              </div>
              <div className="">
                <CustomSelect1 label={"Payment Recieved"}
                  options={paymentRecivedData.map(cData => ({
                    value: cData.id,
                    label: cData.paymentReceived
                  }))}
                  onChange={(option) => setPaymentRecivedId(option.value)}
                />
              </div>
            </div>
            <div className="flex justify-end items-end mt-5">
              <Tooltip title="ADD" arrow placement="bottom">
                <span>
                  <button onClick={handleAddRow}>
                    <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                  </button>
                </span>
              </Tooltip>
            </div>

            {rows.map((row, index) => (
              <div key={index + 1}>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px] mt-3">
                  <div>
                    <CustomAlphatext
                      label={"Invoice Number"}
                      placeholder={"Enter"}
                      value={row.iNo}
                      onChange={(value) => handleRowChange(index, 'iNo', value)}
                    />
                  </div>
                  <div>
                    <CustomSelect1 label={"Payment Terms"}
                      options={paymentTermsData.map(cData => ({
                        value: cData.id,
                        label: cData.paymentTerms
                      }))}
                      onChange={(option) => handleRowChange(index, 'paymentTermsId', option.value)}
                    />
                  </div>
                  <div className="">
                    <CustomDate label={"Payment received"} placeholder={"Enter"}
                      value={row.date}
                      onChange={(value) => handleRowChange(index, 'date', value)}
                    />
                  </div>
                  <div className="">
                    <CustomSelect1 label={"Currency Type"}
                      options={currencyTypeData.map(cData => ({
                        value: cData.id,
                        label: cData.currencyType
                      }))}
                      onChange={(option) => handleRowChange(index, 'currencyTypeId', option.value)}
                    />
                  </div>
                  <div className="">
                    <CustomInputNumber label={"Amount"} placeholder={"ENTER"}
                      value={row.amt}
                      onChange={(value) => handleRowChange(index, 'amt', value)}
                    />
                  </div>
                  <div className="">
                    <CustomSelect1 label={"Payment Recieved"}
                      options={paymentRecivedData.map(cData => ({
                        value: cData.id,
                        label: cData.paymentReceived
                      }))}
                      onChange={(option) => handleRowChange(index, 'paymentRecivedId', option.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-3">
                  <Tooltip title="REMOVE" arrow placement="bottom">
                    <span>
                      <button onClick={() => handleRemoveRow(index)} className="">
                        <CiSquareRemove className="h-10 w-9 txt-prp-color " />
                      </button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            ))}

            <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
              <table className="w-full custom-table" border={1}>
                <thead>
                  <tr className="table-heading">
                    <td className="">S.No.</td>
                    <td>Action</td>
                    <td>Invoice Number</td>
                    <td>Payment Terms</td>
                    <td>Payment received </td>
                    <td>Currency Type</td>
                    <td>Amount</td>
                    <td>Payment received Date</td>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentRecords.length > 0 ? (
                    currentRecords.map((item, index) => (
                      <tr key={index}>
                        <td className="text-xs">{startRecordIndex + index + 1}</td>
                        <td className="text-xs">
                          <div className="gap-2">
                            <Tooltip title="EDIT" arrow placement="left">
                              <span>
                                <CustomButton1
                                  className="bg-sixt text-white grow max-w-[50px]"
                                  icon={<BiEdit />}
                                  onClick={() => handleEditButtonClick(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="VIEW" arrow placement="bottom">
                              <span>
                                <CustomButton4
                                  className="bg-eye text-white grow max-w-[50px]"
                                  icon={<BsEyeFill />}
                                  onClick={() => handleViewButtonClick(item.id)}
                                />
                              </span>
                            </Tooltip>

                            <Tooltip title="DELETE" arrow placement="right">
                              <span>
                                <Button
                                  variant={"contained"}
                                  size={"small"}
                                  className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                  type={"button"}
                                  onClick={() => handleDeleteRecord(item.id)}
                                >
                                  <RiDeleteBin6Line />
                                </Button>
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                        <td className="text-xs">{item.invoiceNumber}</td>
                        <td className="text-xs">{item.paymentTerms}</td>
                        <td className="text-xs">{item.paymentReceived}</td>
                        <td className="text-xs">{item.currencyType}</td>
                        <td className="text-xs">{item.amount}</td>
                        <td className="text-xs">{formatDateTest(item.paymentReceivedDate)}</td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-xs text-center p-2 ">No LC received details available.</div>
                  )}
                </tbody>
              </table>

            </div>

            <div className="flex items-center justify-between mt-5">
              <div className="text-sm text-black font-normal">
                Displaying {startRecordIndex + 1} to {Math.min(endRecordIndex, totalRecords)} of {totalRecords} records
              </div>

              <div className="flex gap-2">
                <div className="flex items-center">
                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>

                  <button
                    className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <CustomCheckBox3
                label={"Payment / LC Closed"}
                state={prlClose}
                setState={setPrlClose}
              ></CustomCheckBox3>
            </div>
          </Accordion>
        </div>

        <div className="flex justify-center gap-5 pt-3">
          <div onClick={() => setUpdatePopup(true)}>
            <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
          </div>
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>

      {recordEdit && (
        <PopUp>
          <PaymentReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord}
            onCancel={() => {
              setRecordEdit(false)
            }}
          />
        </PopUp>
      )}

      {recordView && (
        <PopUp>
          <PaymentReceivedEditInView
            record={selectedRecord}
            onCancel={() => setRecordView(false)}
          />
        </PopUp>
      )}

      {updatePopup && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <ToastContainer autoClose={2000} />

    </>
  )
}
