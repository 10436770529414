import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewPOIssued({ selectedValue }) {
    const piI_IsClosed = selectedValue?.piI_IsClosed ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    {/* Check if selectedValue and piIssuedList are defined and have items */}
                    {selectedValue && selectedValue.piIssuedList && selectedValue.piIssuedList.length > 0 ? (
                        selectedValue.piIssuedList.map((item, index) => (
                            <React.Fragment key={index}>
                                <CustomViewLabel label={`Issued date ${index + 1}`} value={formatDateTest(item.piIssueDate)} />
                                <CustomViewLabel label={`PI Number ${index + 1}`} value={item.piNumber} />
                                <CustomFileViewer fileURL={item.piImageURL} fileName={item.piOriginalFileName ?? ''} label={`PI Upload ${index + 1}`} />
                            </React.Fragment>
                        ))
                    ) : (
                        <p>No PI Issued Data Available</p>
                    )}
                    <CustomViewLabel label="PI Issued Closed" value={piI_IsClosed} />
                    <CustomViewLabel label="PI Issued Closed Date " value={formatDateTest(selectedValue?.piI_CloseDate)} />
                </div>
            </div>
        </>
    );
}
