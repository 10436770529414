// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ManagePOView({ selectedValue }) {

    const createdDate = selectedValue?.createdDate ? new Date(selectedValue.createdDate).toLocaleDateString('en-GB') : 'N/A';
    const fileURL = selectedValue?.pO_ImageURL;
    const fileName = selectedValue?.pO_OriginalFileName ?? '';
    const pO_IsPOStatusClosed = selectedValue?.pO_IsPOStatusClosed ? 'True' : 'False';

    return (
        <>
            <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                <CustomViewLabel label="Customer Name" value={selectedValue?.customerName} />
                <CustomViewLabel label="Parent Customer" value={selectedValue?.parentCustomer} />
                <CustomViewLabel label="Country" value={selectedValue?.countryName} />
                <CustomViewLabel label="Port of Discharge" value={selectedValue?.pO_PortDischarge} />
                <CustomViewLabel label="PO Received" value={selectedValue?.pO_IsPOReceived} />
                <CustomViewLabel label="PO Number" value={selectedValue?.pO_PONumber} />
                <CustomViewLabel label="Payment Terms" value={selectedValue?.pO_PaymentTerms} />
                <CustomViewLabel label="Qty" value={selectedValue?.pO_Quantity} />
                <CustomViewLabel label="Currency Type" value={selectedValue?.pO_CurrencyType} />
                <CustomViewLabel label="Currency Value" value={selectedValue?.pO_CurrencyValue} />
                <CustomViewLabel label="Paper Type" value={selectedValue?.pO_PaperType} />
                <CustomViewLabel label="Brand" value={selectedValue?.pO_Brand} />
                <CustomViewLabel label="Type of Packaging" value={selectedValue?.pO_TypeOfPackaging} />
                <CustomViewLabel label="Delivery Terms" value={selectedValue?.pO_DeliveryTerms} />
                <CustomFileViewer fileURL={fileURL} fileName={fileName} label="PO Received Upload" />
                <CustomViewLabel label="PO Status" value={pO_IsPOStatusClosed} />
                <CustomViewLabel label="Created Date " value={createdDate} />
                <CustomViewLabel label="Created By " value={selectedValue?.creatorName} />
            </div>
        </>
    )
}
