// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import Modal from 'react-modal';
// COMPONENTS
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomTextField2 from "../../general-components/CustomTextField2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomBrowse from "../../general-components/CustomBrowse.component"
import Accordion from "../../general-components/Accodion";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton4 from "../../general-components/CustomButton4.component";
import CustomMultitext from "../../general-components/CustomMultitext.component";
import CustomButton2 from "../../general-components/CustomButton2.component";

import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { MdCancel } from "react-icons/md";

import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import LCReceivedEditInView from "./LCReceivedEditInView";
import LCReceivedEditInEdit from "./LCReceivedEditInEdit";
import axios from 'axios';
import { MdDeleteForever } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Tooltip } from "@mui/material";

export default function LCReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };
  const [prlClose, setPrlClose] = useState(false);
  const [rows, setRows] = useState([]);
  const [date, setDate] = useState("");
  const [attach, setAttach] = useState("");
  const [recordView, setRecordView] = useState("");
  const [recordEdit, setRecordEdit] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [lcNumber, setLcNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);  // To store the selected file URL
  const [fileType, setFileType] = useState('');  // To store the file type (image/pdf)
  const [fileName, setFileName] = useState('');  // To store the file name

  const openModal = (fileUrl, type, name) => {
    setSelectedFileUrl(fileUrl);
    setFileType(type);
    setFileName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFileUrl(null);
    setFileType('');
    setFileName('');
  };

  const handleAddRow = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const newRow = { id: 0, date: currentDate, lcNumber: "", attach: '' };
    setRows([...rows, newRow]);
  };

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);

  useEffect(() => {
    if (selectedValue) {
      setPrlClose(selectedValue.plR_IsPaymentOrLCClosed)
    }
  }, [selectedValue]);

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const token = localStorage.getItem('DIYAN_IE_authToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Conditionally create rows with LC data if any is provided
      const rowsWithDynamicIds = rows.map((row) => ({
        id: 0,
        receivedDate: row.date || null,
        lcNumber: row.lcNumber || "",
        imageName: "",
        originalFileName: row.attach.name,
        image_Base64: row.attach.base64
      }));

      // Include the main LC received data if any field is filled
      let lcReceivedDetail = [];
      if (lcNumber || attach) {
        lcReceivedDetail.push({
          id: 0,
          receivedDate: date || null,
          lcNumber: lcNumber || "",
          imageName: "",
          originalFileName: attach.name,
          image_Base64: attach.base64
        });
      }

      // Add rows if any exist
      if (rowsWithDynamicIds.length > 0) {
        lcReceivedDetail = [...lcReceivedDetail, ...rowsWithDynamicIds];
      }

      const requestBody = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 2,
          paymentOrLCClosed: prlClose,
          paymentReceivedDetail: [],
          // Only include lcReceivedDetail if it has data
          ...(lcReceivedDetail.length > 0 && { lcReceivedDetail })
        }
      };

      console.log("testing--->", requestBody);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleEditButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordEdit(true);
  };

  const handleViewButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordView(true);
  };

  const handleUpdateRecord = (updatedRecord) => {
    const updatedDetails = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map(item =>
      item.id === updatedRecord.id ? updatedRecord : item
    );

    setSelectedValue(prev => ({
      ...prev,
      paymentReceived_Or_LCReceivedDetail: {
        ...prev.paymentReceived_Or_LCReceivedDetail,
        lcReceivedDetail: updatedDetails
      }
    }));
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const currentRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail.slice(startRecordIndex, endRecordIndex) || [];

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleDeleteRecord = async (id) => {
    console.log(`Attempting to delete record with ID: ${id}`); // Log the ID being passed

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteLCReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Record with ID ${id} deleted successfully`); // Log successful deletion
        toast.success('Record deleted successfully');

        setSelectedValue(prevState => ({
          ...prevState,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevState.paymentReceived_Or_LCReceivedDetail,
            lcReceivedDetail: prevState.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.filter(record => record.id !== id)
          }
        }));
      } else {
        console.error(`Failed to delete the record with ID ${id}:`, response.statusText); // Log failure details
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      console.error(`Error deleting the record with ID ${id}:`, error); // Log error details
      toast.error('Error deleting the record');
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Payment Received / LC Received</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex === 0}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex === 1}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex === 2}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Payment Received / LC Received"}
            isOpen={openAccordionIndex === 3}
            onClick={() => handleAccordionClick(3)}
          >
            {/* <EditLC /> */}
            <div className="max-w-full flex gap-4 items-center mt-5 w-[1000px]">
              <div className="w-[30%]">
                <CustomDate
                  label="LC Received"
                  value={date}
                  onChange={setDate}
                />
              </div>
              <div className="mt-1 w-[25%]">
                <CustomTextField2
                  label="LC Number"
                  value={lcNumber}
                  onChange={setLcNumber}
                  placeholder="Enter"
                  isRequired={false}
                />
              </div>
              <div className="w-[40%]">
                <CustomBrowse
                  label=" LC Received Upload"
                  id={`poissu32423ededit-edit-0`}
                  value={attach}
                  onChange={setAttach}
                />
              </div>
              <div className="flex mt-5 w-[5%]">
                <Tooltip title="ADD" arrow placement="bottom">
                  <span>
                    <button onClick={handleAddRow}>
                      <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                    </button>
                  </span>
                </Tooltip>
              </div>
            </div>

            {rows.map((row, index) => (
              <div key={index} className="max-w-full flex gap-4 items-center mt-5 w-[1000px]">
                <div className="w-[30%]">
                  <CustomDate
                    label="LC Received"
                    value={row.date}
                    onChange={(value) => handleRowChange(index, 'date', value)}
                  />
                </div>
                <div className="mt-1 w-[25%]">
                  <CustomMultitext
                    label="LC Number"
                    value={row.lcNumber}
                    onChange={(value) => handleRowChange(index, 'lcNumber', value)}
                    placeholder="Enter"
                    isRequired={false}
                  />
                </div>
                <div className="w-[40%]">
                  <CustomBrowse
                    id={`poissudgfededit-edit-${index}`}
                    label=" LC Received Upload"
                    value={row.attach}
                    onChange={(value) => handleRowChange(index, 'attach', value)}
                  />
                </div>
                <div className="flex mt-5 w-[5%]">
                  <Tooltip title="REMOVE" arrow placement="bottom">
                    <span>
                      <button onClick={() => handleRemoveRow(index)}>
                        <CiSquareRemove className="h-8 w-8 txt-prp-color" />
                      </button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            ))}

            <div>
              <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
                <table className="w-full custom-table" border={1}>
                  <thead>
                    <tr className="table-heading">
                      <td className="">S.No.</td>
                      <td>Action</td>
                      <td>LC Received</td>
                      <td>Lc Number</td>
                      <td> LC Received Upload</td>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {currentRecords.length > 0 ? (
                      currentRecords.map((item, index) => (
                        <tr key={index}>
                          <td className="text-xs">{index + 1}</td>
                          <td className="text-xs">
                            <div className="gap-2">
                              <Tooltip title="EDIT" arrow placement="left">
                                <span>
                                  <CustomButton1
                                    className="bg-sixt text-white grow max-w-[50px]"
                                    icon={<BiEdit />}
                                    onClick={() => handleEditButtonClick(item.id)}
                                  />
                                </span>
                              </Tooltip>

                              <Tooltip title="VIEW" arrow placement="bottom">
                                <span>
                                  <CustomButton4
                                    className="bg-eye text-white grow max-w-[50px]"
                                    icon={<BsEyeFill />}
                                    onClick={() => handleViewButtonClick(item.id)}
                                  />
                                </span>
                              </Tooltip>

                              <Tooltip title="DELETE" arrow placement="right">
                                <span>
                                  <Button
                                    variant={"contained"}
                                    size={"small"}
                                    className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                    type={"button"}
                                    onClick={() => handleDeleteRecord(item.id)}
                                  >
                                    <RiDeleteBin6Line />
                                  </Button>
                                </span>
                              </Tooltip>
                            </div>
                          </td>
                          <td className="text-xs">{formatDateTest(item.receivedDate)}</td>
                          <td className="text-xs">{item?.lcNumber ?? '-'}</td>
                          <td>
                            <div>
                              <div className='flex justify-between items-center'>
                                <div
                                  onClick={() => openModal(item.imageURL, item.originalFileName?.endsWith('.pdf') ? 'application/pdf' : 'image/', item.originalFileName)}
                                >
                                  <Tooltip title="View Upload" arrow placement="bottom">
                                    <span>
                                      <p className='txt-prp-color underline cursor-pointer text-xs'>{item?.originalFileName ?? '-'}</p>
                                    </span>
                                  </Tooltip>
                                </div>
                                {item?.imageURL && (
                                  <div>
                                    {item.originalFileName?.endsWith(".pdf") ? (
                                      <embed
                                        src={item.imageURL}
                                        type="application/pdf"
                                        className="w-10 h-10 border border-[#3D3D3D66]"
                                      />
                                    ) : (
                                      <img
                                        src={item.imageURL}
                                        alt="LC Received Upload"
                                        className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center"
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="text-xs text-center p-2 ">No Lc received details available.</div>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="flex items-center justify-between mt-5">
                <div className="text-sm text-black font-normal">
                  Displaying {startRecordIndex + 1} to {Math.min(endRecordIndex, totalRecords)} of {totalRecords} records
                </div>

                <div className="flex gap-2">
                  <div className="flex items-center">
                    <button
                      className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>

                    <button
                      className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <CustomCheckBox3
                label={"Payment / LC Closed"}
                state={prlClose}
                setState={setPrlClose}
              ></CustomCheckBox3>
            </div>
          </Accordion>
        </div>

        <div className="flex justify-center gap-5 pt-3">
          <div onClick={() => setUpdatePopup(true)}>
            <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
          </div>
          <div onClick={onCancel}>
            <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
          </div>
        </div>
      </div>

      {recordEdit && (
        <PopUp>
          <LCReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord}
            onUpdate={handleUpdateRecord}
            onCancel={() => {
              setRecordEdit(false)
            }}
          />
        </PopUp>
      )}

      {recordView && (
        <PopUp>
          <LCReceivedEditInView
            record={selectedRecord}
            onCancel={() => setRecordView(false)}
          />
        </PopUp>
      )}

      {updatePopup && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="View File"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="coman-modal-img-end">
          <p>{fileName}</p>
          <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
            <MdCancel size={24} />
          </button>
        </div>
        {fileType === 'image/' && selectedFileUrl && (
          <img src={selectedFileUrl} alt="Enlarged View" className="w-full h-auto" />
        )}
        {fileType === 'application/pdf' && selectedFileUrl && (
          <embed src={selectedFileUrl} type="application/pdf" className="w-full h-96" />
        )}
      </Modal>

      <ToastContainer autoClose={2000} />
    </>
  )
}
