// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewContainersUnderLoading({ selectedValue }) {
    const cuL_IsContainersUnderLoadingClose = selectedValue?.cuL_IsContainersUnderLoadingClose ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate;
    };

    const cuL_ContainersUnderLoadingClosedDate = formatDateTest(selectedValue?.cuL_ContainersUnderLoadingClosedDate);

    return (
        <>
            <div>
                <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">

                    {/* Mapping through containersUnderLoadingList array to display each item */}
                    {selectedValue?.containersUnderLoadingList?.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label={`Container Number ${index + 1}`} value={item.containerCount} />

                            <div>
                                {item.containersUnderLoadingImagesList.map((image, imgIndex) => (
                                    <React.Fragment key={imgIndex}>
                                        <CustomFileViewer fileURL={image.containerImageURL} fileName={image.containerOriginalFileName ?? ''} label={`Container Under Loading Images ${imgIndex + 1}`} />
                                    </React.Fragment>
                                ))}
                            </div>

                        </React.Fragment>
                    ))}
                </div>

                <div className="max-w-full grid grid-cols-2 mt-4 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Container Under Loading Closed" value={cuL_IsContainersUnderLoadingClose} />
                    <CustomViewLabel label="Container Under Loading Closed Date" value={cuL_ContainersUnderLoadingClosedDate} />
                </div>
            </div>
        </>
    )
}
