import * as React from "react";
import { useState } from "react";
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewPayment({ selectedValue }) {
    const plR_IsPaymentOrLCClosed = selectedValue?.plR_IsPaymentOrLCClosed ? 'True' : 'False';

    const formatDateTest = (dateString) => {
        if (!dateString) return 'N/A';

        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleString('en-US', options);
    };

    return (
        <div>
            <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                {selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0 ? (
                    selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.map((item, index) => (
                        <React.Fragment key={index}>
                            <CustomViewLabel label="Invoice Number" value={item.invoiceNumber} />
                            <CustomViewLabel label="Payment Terms" value={item.paymentTerms} />
                            <CustomViewLabel label="Payment Received" value={item.paymentReceived} />
                            <CustomViewLabel label="Currency Type" value={item.currencyType} />
                            <CustomViewLabel label="Amount" value={item.amount} />
                            <CustomViewLabel label="Payment Received Date" value={formatDateTest(item.paymentReceivedDate)} />
                        </React.Fragment>
                    ))
                ) : (
                    <div className="text-xs ">No payment details available.</div>
                )}
            </div>
            <div className="mt-4">
            <CustomViewLabel label="Payment / Lc Received Closed" value={plR_IsPaymentOrLCClosed} />
            </div>
        </div>
    );
}
