// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import CustomFileViewer from "../../general-components/CustomFileViewer.component";

export default function ViewBookingIssue({ selectedValue }) {
    const bI_BookingIssueAcceptedDate = selectedValue?.bI_BookingIssueAcceptedDate ? new Date(selectedValue.bI_BookingIssueAcceptedDate).toLocaleDateString('en-GB') : 'N/A';
    const bI_ETD = selectedValue?.bI_ETD ? new Date(selectedValue.bI_ETD).toLocaleDateString('en-GB') : 'N/A';
    const bI_ETA = selectedValue?.bI_ETA ? new Date(selectedValue.bI_ETA).toLocaleDateString('en-GB') : 'N/A';

    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
                    <CustomViewLabel label="ETD" value={bI_ETD} />
                    <CustomViewLabel label="ETA" value={bI_ETA} />
                    <CustomFileViewer label={"Booking Issue Upload"} fileURL={selectedValue?.bI_ImageURL} fileName={selectedValue?.bI_OriginalFileName ?? ''} />
                    <CustomViewLabel label="Booking Issued Close Date " value={bI_BookingIssueAcceptedDate} />
                    <CustomViewLabel label="Booking Issued Close" value={selectedValue?.bI_IsBookingIssueAccepted} />
                </div>
            </div>
        </>
    )
}

