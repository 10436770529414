// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewDocumentSend({ selectedValue }) {
    const ddS_DocumentSendDHL_SubmittedDate = selectedValue?.ddS_DocumentSendDHL_SubmittedDate ? new Date(selectedValue.ddS_DocumentSendDHL_SubmittedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="Document Send Dhl / Submitted Date" value={ddS_DocumentSendDHL_SubmittedDate} />
                    <CustomViewLabel label="Document Send Dhl / Submitted Close" value={selectedValue?.ddS_IsDocumentSendDHL_Submitted} />
                </div>
            </div>
        </>
    )
}

