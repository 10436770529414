// CORE
import * as React from "react";
import { useState } from "react";

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";
import Accordion from "../../general-components/Accodion";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";

import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import ViewPaymentLCReceived from "../ManagePaymentReceived_LCReceived/ViewPaymentLCReceived";
import ViewOrderAccepted from "../ManageOrderAccepted/ViewOrderAccepted";
import ViewOrderUnderProcess from "../ManageOrderUnderProcess/ViewOrderUnderProcess";
import ViewBookingIssue from "../ManageBookingIssue/ViewBookingIssue";
import ViewContainersUnderLoading from "../ManageContainersUnderLoading/ViewContainersUnderLoading";
import ViewInvoiceGenerated from "./ViewInvoiceGenerated";
import CustomButton2 from "../../general-components/CustomButton2.component";



export default function ManageInvoiceGeneratedView({ onCancel, selectedValue }) {
    let [checkboxAdd, setCheckboxAdd] = useState(true);
    const [openAccordionIndex, setOpenAccordionIndex] = useState(8);

    const handleAccordionClick = (index) => {
        setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
    };

    return (
        <>
            <div className="pb-10 bg-white rounded-lg">
                <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
                    <h1 className=" font-weight-[400]">View Invoice Generated </h1>
                    <div className="flex items-center text-sm"></div>
                </div>

                <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">

                    <Accordion
                        title={"PO received"}
                        isOpen={openAccordionIndex === 0}
                        onClick={() => handleAccordionClick(0)}
                    >
                        <ManagePOView
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion
                        title={"PI Issued"}
                        isOpen={openAccordionIndex === 1}
                        onClick={() => handleAccordionClick(1)}
                    >
                        <ViewPOIssued
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion
                        title={"PI Confirmation"}
                        isOpen={openAccordionIndex === 2}
                        onClick={() => handleAccordionClick(2)}
                    >
                        <ViewPIConfirmedbyCustomer
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Payment Received / LC Received"}
                        isOpen={openAccordionIndex === 3}
                        onClick={() => handleAccordionClick(3)}
                    >
                        <ViewPaymentLCReceived
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Accepted"}
                        isOpen={openAccordionIndex === 4}
                        onClick={() => handleAccordionClick(4)}
                    >
                        <ViewOrderAccepted
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Order Under Process"}
                        isOpen={openAccordionIndex === 5}
                        onClick={() => handleAccordionClick(5)}
                    >
                        <ViewOrderUnderProcess
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Booking Issued"}
                        isOpen={openAccordionIndex === 6}
                        onClick={() => handleAccordionClick(6)}
                    >
                        <ViewBookingIssue
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Containers Under Loading"}
                        isOpen={openAccordionIndex === 7}
                        onClick={() => handleAccordionClick(7)}
                    >
                        <ViewContainersUnderLoading
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                    <Accordion title={"Invoice Generated"}
                        isOpen={openAccordionIndex === 8}
                        onClick={() => handleAccordionClick(8)}
                    >
                        <ViewInvoiceGenerated
                            selectedValue={selectedValue}
                        />
                    </Accordion>
                </div>
                <div className="flex justify-center gap-5 pt-3">
                    <div onClick={onCancel}>
                        <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                    </div>
                </div>
            </div>
        </>
    );
}


