// CORE
import * as React from "react";
import CustomViewLabel from "../../general-components/CustomViewLabel.component";

export default function ViewAwbGeneratedPending({ selectedValue }) {
    const poC_POClosedDate = selectedValue?.poC_POClosedDate ? new Date(selectedValue.poC_POClosedDate).toLocaleDateString('en-GB') : 'N/A';
    return (
        <>
            <div>
                <div className=" max-w-full grid grid-cols-2 gap-4 md:grid-cols-2 w-[1000px]">
                    <CustomViewLabel label="PO Closed Date" value={poC_POClosedDate} />
                    <CustomViewLabel label="PO Close" value={selectedValue?.poC_IsPOClosed} />
                </div>
            </div>
        </>
    )
}

