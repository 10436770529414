import React, { useState } from 'react';
import { ImEnlarge } from 'react-icons/im';
import { MdCancel } from 'react-icons/md';
import { FaDownload } from 'react-icons/fa';
import Modal from 'react-modal';

const CustomFileViewer = ({ fileURL, fileName, label }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEnlarge = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // const handleDownload = () => {
  //   const url = fileURL;
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.download = fileName;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownload = () => {
  const url = fileURL;
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 100);
};


  const getFileExtension = (filename) => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  };

  const isPDF = fileURL && getFileExtension(fileName) === 'pdf';

  return (
    <div className="flex flex-col gap-1 relative">
      <label className="text-xs font-[400]">
        {label.toUpperCase()}
      </label>
      <div className="flex px-2 justify-between items-center">
        <p className='text-gray-400 text-xs'>{fileName}</p>
        {fileURL && (
          <div className="flex items-center space-x-2">
            <button onClick={handleDownload} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
              <FaDownload h-8 w-8 />
            </button>
            <button onClick={handleEnlarge} className="bg-white txt-prp-color border border-prp p-1.5 rounded">
              <ImEnlarge h-8 w-8 />
            </button>
          </div>
        )}
        <div>
          {!fileURL && (
            <div className="flex w-10 h-10 rounded-full border border-[#3D3D3D66]">
              <span className="text-[#3D3D3D66] flex items-center justify-center text-[10px] text-center">
                No Preview
              </span>
            </div>
          )}
          {fileURL && isPDF ? (
            <embed
              src={fileURL}
              type="application/pdf"
              className="w-10 h-10 border border-[#3D3D3D66]"
            />
          ) : (
            <img
              src={fileURL}
              alt="Preview"
              className="w-10 h-10 rounded-full border border-[#3D3D3D66]"
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Enlarge File"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="coman-modal-flex">
          {fileName && (
            <div className="coman-modal-text">
              <p>{fileName}</p>
            </div>
          )}
          <div className="coman-modal">
            <button onClick={handleDownload} className="modal-btn-comman modal-btn-dwl">
              <FaDownload size={18} />
            </button>
            <button onClick={closeModal} className="modal-btn-comman modal-btn-cnl">
              <MdCancel size={18} />
            </button>
          </div>
        </div>
        {fileURL && isPDF ? (
          <embed
            src={fileURL}
            type="application/pdf"
            className="w-full h-auto"
          />
        ) : (
          <img
            src={fileURL}
            alt="Enlarged"
            className="w-full h-auto"
          />
        )}
      </Modal>
    </div>
  );
};

export default CustomFileViewer;
