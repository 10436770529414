// CORE
import * as React from "react";
import { useState, useEffect } from "react";
import '../../../assets/css/selectbar.css';
import axios from 'axios';
import { Button, Tooltip } from "@mui/material";
import Modal from 'react-modal';

// COMPONENTS
import CustomButton1 from "../../general-components/CustomButton1.component";
import CustomSelect1 from "../../general-components/CustomSelect1.component";
import CustomCheckBox2 from "../../general-components/CustomCheckBox2.component";
import CustomCheckBox3 from "../../general-components/CustomCheckBox3.component";
import CustomBrowse from "../../general-components/CustomBrowse.component"
import CustomInputNumber from '../../general-components/CustomInputNumber.component'
import Accordion from "../../general-components/Accodion";
import CustomDate from "../../general-components/CustomDate.component";
import CustomButton2 from "../../general-components/CustomButton2.component";
import ManagePOView from "../ManagePO/ManagePOView";
import ViewPOIssued from "../ManagePOIssued/ViewPOIssued";
import ViewPIConfirmedbyCustomer from "../ManagePIConfirmedbyCustomer/AllPi/ViewPIConfirmedbyCustomer";
import PopUp from "../../general-components/PopUp.componenet";
import CustomButton4 from "../../general-components/CustomButton4.component";
import CustomMultitext from "../../general-components/CustomMultitext.component";
import CustomAlphatext from "../../general-components/CustomAlphatext.component";

import { BsEyeFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsPlusSquareFill } from "react-icons/bs";
import { CiSquareRemove } from "react-icons/ci";
import { MdCancel } from "react-icons/md";

import LCReceivedEditInEdit from "../LCReceived/LCReceivedEditInEdit";
import LCReceivedEditInView from "../LCReceived/LCReceivedEditInView";
import PaymentReceivedEditInEdit from "../PaymentReceived/PaymentReceivedEditInEdit";
import PaymentReceivedEditInView from "../PaymentReceived/PaymentReceivedEditInView";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PaymentLCReceivedEdit({ onCancel, selectedValue, setSelectedValue }) {
  const [checkboxPi, setCheckboxPi] = useState(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(3);
  const [prlCloseAll, setPlrCloseAll] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [updatePopup, setUpdatePopup] = useState(false);
  const [updatePopup1, setUpdatePopup1] = useState(false);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? -1 : index);
  };

  const handleCheckboxPiChange = () => {
    setCheckboxPi(!checkboxPi);
    if (!checkboxPi) {
      setCheckbox(false);
    }
  };

  const handleCheckboxChange = () => {
    setCheckbox(!checkbox);
    if (!checkbox) {
      setCheckboxPi(false);
    }
  };

  useEffect(() => {
    if (selectedValue) {
      const hasPaymentReceived = selectedValue.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length > 0;
      const hasLcReceived = selectedValue.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length > 0;

      if (hasLcReceived) {
        setCheckboxPi(false);
        setCheckbox(true); // Set LC received as checked if LC has values
      } else if (hasPaymentReceived) {
        setCheckboxPi(true);
        setCheckbox(false); // Set Payment received as checked if only payment has values
      } else {
        setCheckboxPi(true); // Default to true if both are empty
        setCheckbox(false);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (selectedValue) {
      setPlrCloseAll(selectedValue.plR_IsPaymentOrLCClosed);
    }
  }, [selectedValue])

  const [checkbox, setCheckbox] = useState(false);
  // const [checkboxEditPi, setCheckboxEditPi] = useState(false);
  const [rows, setRows] = useState([]);
  const [date, setDate] = useState("");
  const [attach, setAttach] = useState("");
  const [entries, setEntries] = useState([]);
  const [recordView, setRecordView] = useState("");
  const [recordEdit, setRecordEdit] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [lcNumber, setLcNumber] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 5;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);  // To store the selected file URL
  const [fileType, setFileType] = useState('');  // To store the file type (image/pdf)
  const [fileName, setFileName] = useState('');  // To store the file name

  const openModal = (fileUrl, type, name) => {
    setSelectedFileUrl(fileUrl);
    setFileType(type);
    setFileName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFileUrl(null);
    setFileType('');
    setFileName('');
  };

  const handleAddRow = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const newRow = { id: 0, date: currentDate, lcNumber: "", attach: '' };
    setRows([...rows, newRow]);
  };

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate(currentDate);
  }, []);

  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const token = localStorage.getItem('DIYAN_IE_authToken');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Conditionally create rows with LC data if any is provided
      const rowsWithDynamicIds = rows.map((row) => ({
        id: 0,
        receivedDate: row.date || null,
        lcNumber: row.lcNumber || "",
        imageName: "",
        originalFileName: row.attach.name,
        image_Base64: row.attach.base64
      }));

      // Include the main LC received data if any field is filled
      let lcReceivedDetail = [];
      if (lcNumber || attach) {
        lcReceivedDetail.push({
          id: 0,
          receivedDate: date || null,
          lcNumber: lcNumber || "",
          imageName: "",
          originalFileName: attach.name,
          image_Base64: attach.base64
        });
      }

      // Add rows if any exist
      if (rowsWithDynamicIds.length > 0) {
        lcReceivedDetail = [...lcReceivedDetail, ...rowsWithDynamicIds];
      }

      const requestBody = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 2,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: [],
          // Only include lcReceivedDetail if it has data
          ...(lcReceivedDetail.length > 0 && { lcReceivedDetail })
        }
      };


      console.log("testing--->", requestBody);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }
    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTest = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  const handleEditButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordEdit(true);
  };

  const handleViewButtonClick = (id) => {
    const record = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.find(item => item.id === id);
    setSelectedRecord(record);
    setRecordView(true);
  };


  const handleUpdateRecord = (updatedRecord) => {
    const updatedDetails = selectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.map(item =>
      item.id === updatedRecord.id ? updatedRecord : item
    );

    setSelectedValue(prev => ({
      ...prev,
      paymentReceived_Or_LCReceivedDetail: {
        ...prev.paymentReceived_Or_LCReceivedDetail,
        lcReceivedDetail: updatedDetails
      }
    }));
  };

  const totalRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const startRecordIndex = (currentPage - 1) * recordsPerPage;
  const endRecordIndex = startRecordIndex + recordsPerPage;
  const currentRecords = selectedValue?.paymentReceived_Or_LCReceivedDetail?.lcReceivedDetail.slice(startRecordIndex, endRecordIndex) || [];

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  // =========== 11 
  const [openAccordionIndex1, setOpenAccordionIndex1] = useState(0);
  const [iNo1, setINo1] = useState("");
  const [amt1, setAmt1] = useState("");
  const [date1, setDate1] = useState("");
  const [rows1, setRows1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [paymentTermsData1, setPaymentsTermsData1] = useState([]);
  const [currencyTypeData1, setCurrencyTypeData1] = useState([]);
  const [paymentRecivedData1, setPaymentRecivedData1] = useState([]);
  const [paymentTermsId1, setPaymentsTermsId1] = useState("");
  const [currencyTypeId1, setCurrencyTypeId1] = useState("");
  const [paymentRecivedId1, setPaymentRecivedId1] = useState("");

  const [recordView1, setRecordView1] = useState("");
  const [recordEdit1, setRecordEdit1] = useState("");
  const [selectedRecord1, setSelectedRecord1] = useState(null);

  const [currentPage1, setCurrentPage1] = useState(1);
  const recordsPerPage1 = 5;

  const token1 = localStorage.getItem('DIYAN_IE_authToken');
  const dataActive1 = { searchText: "", isActive: true };

  const fetchData1 = async (url, setData, errorMsg) => {
    setIsLoading1(true);
    try {
      const response = await axios.post(url, dataActive1, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
      });

      if (response.data.isSuccess && Array.isArray(response.data.data)) {
        setData(response.data.data);
      } else {
        console.log("Invalid response format");
      }
    } catch (error) {
      toast.error(errorMsg);
    } finally {
      setIsLoading1(false);
    }
  };

  useEffect(() => {
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentTermsList`, setPaymentsTermsData1, "Error fetching Payment Terms Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetCurrencyTypeList`, setCurrencyTypeData1, "Error fetching Currency Type Data");
    fetchData1(`${process.env.REACT_APP_BASE_URL}/AdminMaster/GetPaymentReceivedList`, setPaymentRecivedData1, "Error fetching Payment Received Data");
  }, []);

  const handleAccordionClick1 = (index) => {
    setOpenAccordionIndex1(index === openAccordionIndex1 ? -1 : index);
  };

  const handleAddRow1 = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const newRow1 = { iNo1: '', amt1: '', date1: currentDate, paymentTermsId1: "", currencyTypeId1: "", paymentRecivedId1: "" };
    setRows1([...rows1, newRow1]);
  };


  const handleRemoveRow1 = (index) => {
    const updatedRows1 = rows1.filter((row1, rowIndex1) => rowIndex1 !== index);
    setRows1(updatedRows1);
  };

  const handleRowChange1 = (index, field, value) => {
    const updatedRows1 = rows1.map((row1, rowIndex1) =>
      rowIndex1 === index ? { ...row1, [field]: value } : row1
    );
    setRows1(updatedRows1);
  };

  useEffect(() => {
    // Set default date to current date
    const currentDate = new Date().toISOString().split("T")[0];
    setDate1(currentDate);
  }, []);

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    setIsLoading1(true);

    try {
      const rowsWithDynamicIds1 = rows1.map((row) => ({
        id: 0,
        invoiceNumber: row.iNo1 || "",
        paymentTermsId: row.paymentTermsId1 || 0,
        paymentReceivedDate: row.date1 || "",
        currencyTypeId: row.currencyTypeId1 || 0,
        amount: row.amt1 || 0,
        paymentReceivedId: row.paymentRecivedId1 || 0
      }));

      // Construct paymentReceivedDetail only if there's at least one field with a non-default value
      const paymentReceivedDetail = [];
      if (iNo1 || paymentTermsId1 || currencyTypeId1 || amt1 || paymentRecivedId1) {
        paymentReceivedDetail.push({
          id: 0,
          invoiceNumber: iNo1 || "",
          paymentTermsId: paymentTermsId1 || 0,
          paymentReceivedDate: date || "",
          currencyTypeId: currencyTypeId1 || 0,
          amount: amt1 || 0,
          paymentReceivedId: paymentRecivedId1 || 0
        });
      }

      // Add rows if any exist
      if (rowsWithDynamicIds1.length > 0) {
        paymentReceivedDetail.push(...rowsWithDynamicIds1);
      }

      const requestBody1 = {
        id: selectedValue.id,
        customerId: selectedValue.customerId || 0,
        paymentReceived_Or_LCReceivedDetails: {
          paymentOrLCReceived: 1,
          paymentOrLCClosed: prlCloseAll,
          paymentReceivedDetail: paymentReceivedDetail.length > 0 ? paymentReceivedDetail : undefined,
          lcReceivedDetail: []
        }
      };

      console.log("Request Body: ", requestBody1);

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ManageTracking/SavePurchaseOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token1}`
        },
        body: JSON.stringify(requestBody1),
      });

      const responseData = await response.json();
      if (responseData.isSuccess) {
        toast.success("Record Updated successfully");
        setUpdatePopup1(false);
        setTimeout(() => {
          // window.location.reload(); // Reload the page or fetch updated list
          onCancel();
        }, 2000);
      } else {
        console.error("Record operation failed", responseData.message);
        toast.error(responseData.message || "Record operation failed");
      }

    } catch (error) {
      console.error("Error performing PO Received operation:", error);
      toast.error("Error performing PO Received operation");
    } finally {
      setIsLoading1(false);
    }
  };

  const formatDateTest1 = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const options1 = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate1 = date.toLocaleString('en-US', options1);
    return formattedDate1;
  };

  const handleViewButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordView1(true);
  };

  const handleEditButtonClick1 = (id) => {
    const record1 = selectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.find(item => item.id === id);
    setSelectedRecord1(record1);
    setRecordEdit1(true);
  };

  const totalRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail?.length || 0;
  const totalPages1 = Math.ceil(totalRecords1 / recordsPerPage1);

  const startRecordIndex1 = (currentPage1 - 1) * recordsPerPage1;
  const endRecordIndex1 = startRecordIndex1 + recordsPerPage1;
  const currentRecords1 = selectedValue?.paymentReceived_Or_LCReceivedDetail?.paymentReceivedDetail.slice(startRecordIndex1, endRecordIndex1) || [];

  const handleNextPage1 = () => {
    setCurrentPage1((prev) => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage1 = () => {
    setCurrentPage1((prev) => Math.max(prev - 1, 1));
  };

  const handlePaymentTermsChange = (selectedOption) => {
    if (selectedOption) {
      setPaymentsTermsId1(selectedOption.value);
    } else {
      setPaymentsTermsId1(null); // Reset to null when cleared
    }
  };

  const handleCurrencyTypeChange = (selectedOption) => {
    if (selectedOption) {
      setCurrencyTypeId1(selectedOption.value);
    } else {
      setCurrencyTypeId1(null); // Reset to null when cleared
    }
  };

  const handlePaymentReceivedChange = (selectedOption) => {
    if (selectedOption) {
      setPaymentRecivedId1(selectedOption.value);
    } else {
      setPaymentRecivedId1(null); // Reset to null when cleared
    }
  };


  const handleCurrencyTypeRowChange = (index, selectedOption) => {
    if (selectedOption) {
      handleRowChange1(index, 'currencyTypeId1', selectedOption.value);
    } else {
      handleRowChange1(index, 'currencyTypeId1', null); // Reset to null when cleared
    }
  };


  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteRecordPayment = async (id) => {
    if (isDeleting) return; // Prevent multiple calls
    setIsDeleting(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeletePaymentReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            paymentReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.paymentReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));

        // toast.success('Record deleted successfully', { autoClose: 5000 });
      } else {
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      toast.error('Error deleting the record');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteRecordLc = async (id) => {
    console.log(`Attempting to delete record with ID: ${id}`); // Log the ID being passed

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/ManageTracking/DeleteLCReceived?Id=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Record with ID ${id} deleted successfully`); // Log successful deletion
        // toast.success('Record deleted successfully');

        setSelectedValue((prevSelectedValue) => ({
          ...prevSelectedValue,
          paymentReceived_Or_LCReceivedDetail: {
            ...prevSelectedValue.paymentReceived_Or_LCReceivedDetail,
            lcReceivedDetail: prevSelectedValue.paymentReceived_Or_LCReceivedDetail.lcReceivedDetail.filter(
              (item) => item.id !== id
            ),
          },
        }));
      } else {
        console.error(`Failed to delete the record with ID ${id}:`, response.statusText); // Log failure details
        toast.error('Failed to delete the record');
      }
    } catch (error) {
      console.error(`Error deleting the record with ID ${id}:`, error); // Log error details
      toast.error('Error deleting the record');
    }
  };

  return (
    <>
      <div className="pb-10 bg-white rounded-lg">
        <div className="flex bg-prp-color p-2 text-white justify-between rounded-t-lg">
          <h1 className=" font-weight-[400]">Edit Payment Received / LC Received</h1>
          <div className="flex items-center text-sm"></div>
        </div>

        <div className="p-5 mx-auto max-w-full w-[1000px] max-h-[600px] overflow-x-auto">
          <Accordion title={"PO recieved"}
            isOpen={openAccordionIndex === 0}
            onClick={() => handleAccordionClick(0)}
          >
            <ManagePOView
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Issued"}
            isOpen={openAccordionIndex === 1}
            onClick={() => handleAccordionClick(1)}
          >
            <ViewPOIssued
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"PI Confirmation"}
            isOpen={openAccordionIndex === 2}
            onClick={() => handleAccordionClick(2)}
          >
            <ViewPIConfirmedbyCustomer
              selectedValue={selectedValue}
            />
          </Accordion>
          <Accordion title={"Payment Received / LC Received"}
            isOpen={openAccordionIndex === 3}
            onClick={() => handleAccordionClick(3)}
          >
            <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px]">
              <div className="">
                <CustomCheckBox2
                  label={"Payment Received"}
                  state={checkboxPi}
                  setState={handleCheckboxPiChange}
                />
              </div>
              <div className="">
                <CustomCheckBox2
                  label={"LC Received"}
                  state={checkbox}
                  setState={handleCheckboxChange}
                />
              </div>
            </div>

            {checkboxPi && (
              <>
                <div className="max-w-full grid grid-cols-2 mt-5 gap-4 md:grid-cols-3 w-[1000px]">
                  <div>
                    <CustomAlphatext
                      label={"Invoice Number"}
                      placeholder={"Enter"}
                      value={iNo1}
                      onChange={setINo1}
                    />
                  </div>
                  <div>
                    <CustomSelect1 label={"Payment Terms"}
                      options={paymentTermsData1.map(cData => ({
                        value: cData.id,
                        label: cData.paymentTerms
                      }))}
                      onChange={handlePaymentTermsChange}
                    />
                  </div>
                  <div className="">
                    <CustomDate label={"Payment received"} placeholder={"Enter"}
                      value={date1}
                      onChange={setDate1}
                    />
                  </div>
                  <div className="">
                    <CustomSelect1 label={"Currency Type"}
                      options={currencyTypeData1.map(cData => ({
                        value: cData.id,
                        label: cData.currencyType
                      }))}
                      onChange={(option) => setCurrencyTypeId1(option.value)}
                    />
                  </div>
                  <div className="">
                    <CustomInputNumber label={"Amount"} placeholder={"ENTER"}
                      value={amt1}
                      onChange={setAmt1}
                    />
                  </div>
                  <div className="">
                    <CustomSelect1 label={"Payment Recieved"}
                      options={paymentRecivedData1.map(cData => ({
                        value: cData.id,
                        label: cData.paymentReceived
                      }))}
                      onChange={(option) => setPaymentRecivedId1(option.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-end items-end mt-5">
                  <Tooltip title="ADD" arrow placement="bottom">
                    <span>
                      <button onClick={handleAddRow1}>
                        <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                      </button>
                    </span>
                  </Tooltip>
                </div>

                {rows1.map((row, index) => (
                  <div key={index + 1}>
                    <div className="max-w-full grid grid-cols-2 gap-4 md:grid-cols-3 w-[1000px] mt-3">
                      <div>
                        <CustomAlphatext
                          label={"Invoice Number"}
                          placeholder={"Enter"}
                          value={row.iNo1}
                          onChange={(value) => handleRowChange1(index, 'iNo1', value)}
                        />
                      </div>
                      <div>
                        <CustomSelect1 label={"Payment Terms"}
                          options={paymentTermsData1.map(cData => ({
                            value: cData.id,
                            label: cData.paymentTerms
                          }))}
                          onChange={(option) => handleRowChange1(index, 'paymentTermsId1', option.value)}
                        />
                      </div>
                      <div className="">
                        <CustomDate label={"Payment received"} placeholder={"Enter"}
                          value={row.date1}
                          onChange={(value) => handleRowChange1(index, 'date1', value)}
                        />
                      </div>
                      <div className="">
                        <CustomSelect1 label={"Currency Type"}
                          options={currencyTypeData1.map(cData => ({
                            value: cData.id,
                            label: cData.currencyType
                          }))}
                          onChange={(option) => handleRowChange1(index, 'currencyTypeId1', option.value)}
                        />
                      </div>
                      <div className="">
                        <CustomInputNumber label={"Amount"} placeholder={"ENTER"}
                          value={row.amt1}
                          onChange={(value) => handleRowChange1(index, 'amt1', value)}
                        />
                      </div>
                      <div className="">
                        <CustomSelect1 label={"Currency Type"}
                          options={currencyTypeData1.map(cData => ({
                            value: cData.id,
                            label: cData.currencyType
                          }))}
                          onChange={(option) => handleRowChange1(index, 'currencyTypeId1', option.value)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-end mt-3">
                      <Tooltip title="REMOVE" arrow placement="bottom">
                        <span>
                          <button onClick={() => handleRemoveRow1(index)} className="">
                            <CiSquareRemove className="h-10 w-9 txt-prp-color " />
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}

                <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
                  <table className="w-full custom-table" border={1}>
                    <thead>
                      <tr className="table-heading">
                        <td className="">S.No.</td>
                        <td>Action</td>
                        <td>Invoice Number</td>
                        <td>Payment Terms</td>
                        <td>Payment received </td>
                        <td>Currency Type</td>
                        <td>Amount</td>
                        <td>Payment received Date</td>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentRecords1.length > 0 ? (
                        currentRecords1.map((item, index) => (
                          <tr key={index}>
                            <td className="text-xs">{startRecordIndex1 + index + 1}</td>
                            <td className="text-xs">
                              <div className="gap-2">
                                <Tooltip title="EDIT" arrow placement="bottom">
                                  <span>
                                    <CustomButton1
                                      className="bg-sixt text-white grow max-w-[50px]"
                                      icon={<BiEdit />}
                                      onClick={() => handleEditButtonClick1(item.id)}
                                    />
                                  </span>
                                </Tooltip>

                                <Tooltip title="VIEW" arrow placement="bottom">
                                  <span>
                                    <CustomButton4
                                      className="bg-eye text-white grow max-w-[50px]"
                                      icon={<BsEyeFill />}
                                      onClick={() => handleViewButtonClick1(item.id)}
                                    />
                                  </span>
                                </Tooltip>

                                <Tooltip title="DELETE" arrow placement="bottom">
                                  <span>
                                    <Button
                                      variant={"contained"}
                                      size={"small"}
                                      className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                      type={"button"}
                                      onClick={() => handleDeleteRecordPayment(item.id)}
                                    >
                                      <RiDeleteBin6Line />
                                    </Button>
                                  </span>
                                </Tooltip>

                              </div>
                            </td>
                            <td className="text-xs">{item.invoiceNumber}</td>
                            <td className="text-xs">{item.paymentTerms}</td>
                            <td className="text-xs">{item.paymentReceived}</td>
                            <td className="text-xs">{item.currencyType}</td>
                            <td className="text-xs">{item.amount}</td>
                            <td className="text-xs">{formatDateTest1(item.paymentReceivedDate)}</td>
                          </tr>
                        ))
                      ) : (
                        <div className="text-xs text-center p-2 ">No Payment received details available.</div>
                      )}
                    </tbody>
                  </table>

                </div>

                <div className="flex items-center justify-between mt-5">
                  <div className="text-sm text-black font-normal">
                    Displaying {startRecordIndex1 + 1} to {Math.min(endRecordIndex1, totalRecords1)} of {totalRecords1} records
                  </div>

                  <div className="flex gap-2">
                    <div className="flex items-center">
                      <button
                        className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                        onClick={handlePrevPage1}
                        disabled={currentPage1 === 1}
                      >
                        Previous
                      </button>

                      <button
                        className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                        onClick={handleNextPage1}
                        disabled={currentPage1 === totalPages1}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <CustomCheckBox3
                    label={"Payment / LC Closed"}
                    state={prlCloseAll}
                    setState={setPlrCloseAll}
                  ></CustomCheckBox3>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                  <div onClick={() => setUpdatePopup1(true)}>
                    <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                  </div>
                  <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </>
            )}

            {checkbox && (
              <>
                {/* <EditLC /> */}
                <div className="max-w-full flex gap-4 items-center mt-5 w-[1000px]">
                  <div className="w-[30%]">
                    <CustomDate
                      label="LC Received"
                      value={date}
                      onChange={setDate}
                    />
                  </div>
                  <div className="mt-1 w-[25%]">
                    <CustomMultitext
                      label="LC Number"
                      value={lcNumber}
                      onChange={setLcNumber}
                      placeholder="Enter"
                      isRequired={false}
                    />
                  </div>
                  <div className="w-[40%] ">
                    <CustomBrowse
                      label="LC Received Upload"
                      id={`poissu32423ededit-edit-0`}
                      value={attach}
                      onChange={setAttach}
                    />
                  </div>
                  <div className="flex mt-5 w-[5%]">
                    <Tooltip title="ADD" arrow placement="bottom">
                      <span>
                        <button onClick={handleAddRow}>
                          <BsPlusSquareFill className="h-8 w-8 txt-prp-color" />
                        </button>
                      </span>
                    </Tooltip>
                  </div>
                </div>

                {rows.map((row, index) => (
                  <div key={index} className="max-w-full flex gap-4 items-center mt-5 w-[1000px]">
                    <div className="w-[30%]">
                      <CustomDate
                        label="LC Received"
                        value={row.date}
                        onChange={(value) => handleRowChange(index, 'date', value)}
                      />
                    </div>
                    <div className="mt-1 w-[25%]">
                      <CustomMultitext
                        label="LC Number"
                        value={row.lcNumber}
                        onChange={(value) => handleRowChange(index, 'lcNumber', value)}
                        placeholder="Enter"
                        isRequired={false}
                      />
                    </div>
                    <div className="w-[40%]">
                      <CustomBrowse
                        id={`poissudgfededit-edit-${index}`}
                        label="LC Received Upload"
                        value={row.attach}
                        onChange={(value) => handleRowChange(index, 'attach', value)}
                      />
                    </div>
                    <div className="flex mt-5 w-[5%]">
                      <Tooltip title="REMOVE" arrow placement="bottom">
                        <span>
                          <button onClick={() => handleRemoveRow(index)}>
                            <CiSquareRemove className="h-8 w-8 txt-prp-color" />
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                ))}

                <div>
                  <div className="mt-5 max-w-[89vw] hide-scrollbar overflow-auto table-container ">
                    <table className="w-full custom-table" border={1}>
                      <thead>
                        <tr className="table-heading">
                          <td className="">S.No.</td>
                          <td>Action</td>
                          <td>LC Received</td>
                          <td>Lc Number</td>
                          <td>LC Received Upload</td>
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {currentRecords.length > 0 ? (
                          currentRecords.map((item, index) => (
                            <tr key={index}>
                              <td className="text-xs">{index + 1}</td>
                              <td className="text-xs">
                                <div className="gap-2">
                                  <Tooltip title="EDIT" arrow placement="bottom">
                                    <span>
                                      <CustomButton1
                                        className="bg-sixt text-white grow max-w-[50px]"
                                        icon={<BiEdit />}
                                        onClick={() => handleEditButtonClick(item.id)}
                                      />
                                    </span>
                                  </Tooltip>

                                  <Tooltip title="VIEW" arrow placement="bottom">
                                    <span>
                                      <CustomButton4
                                        className="bg-eye text-white grow max-w-[50px]"
                                        icon={<BsEyeFill />}
                                        onClick={() => handleViewButtonClick(item.id)}
                                      />
                                    </span>
                                  </Tooltip>


                                  <Tooltip title="DELETE" arrow placement="bottom">
                                    <span>
                                      <Button
                                        variant={"contained"}
                                        size={"small"}
                                        className={`flex gap-2 justify-center items-center relative uppercase bg-red-500`}
                                        type={"button"}
                                        onClick={() => handleDeleteRecordLc(item.id)}
                                      >
                                        <RiDeleteBin6Line />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </td>
                              <td className="text-xs">{formatDateTest(item.receivedDate)}</td>
                              <td className="text-xs">{item?.lcNumber ?? '-'}</td>
                              <td>
                                <div>
                                  <div className='flex justify-between items-center'>
                                    <div
                                      onClick={() => openModal(item.imageURL, item.originalFileName?.endsWith('.pdf') ? 'application/pdf' : 'image/', item.originalFileName)}
                                    >
                                      <Tooltip title="View Upload" arrow placement="bottom">
                                        <span>
                                          <p className='txt-prp-color underline cursor-pointer text-xs'>{item?.originalFileName ?? '-'}</p>
                                        </span>
                                      </Tooltip>
                                    </div>
                                    {item?.imageURL && (
                                      <div>
                                        {item.originalFileName?.endsWith(".pdf") ? (
                                          <embed
                                            src={item.imageURL}
                                            type="application/pdf"
                                            className="w-10 h-10 border border-[#3D3D3D66]"
                                          />
                                        ) : (
                                          <img
                                            src={item.imageURL}
                                            alt="LC Received Upload"
                                            className="h-10 w-10 rounded-full object-cover mx-auto border text-gray-400 text-[10px] text-center"
                                          />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="text-xs text-center p-2 ">No Lc received details available.</div>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="flex items-center justify-between mt-5">
                    <div className="text-sm text-black font-normal">
                      Displaying {startRecordIndex + 1} to {Math.min(endRecordIndex, totalRecords)} of {totalRecords} records
                    </div>

                    <div className="flex gap-2">
                      <div className="flex items-center">
                        <button
                          className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light bg-prp-color`}
                          onClick={handlePrevPage}
                          disabled={currentPage1 === 1}
                        >
                          Previous
                        </button>

                        <button
                          className={`px-2 py-1 text-sm mx-1 cursor-pointer rounded-md text-white font-light  bg-prp-color`}
                          onClick={handleNextPage}
                          disabled={currentPage1 === totalPages1}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <CustomCheckBox3
                    label={"Payment / LC Closed"}
                    state={prlCloseAll}
                    setState={setPlrCloseAll}
                  ></CustomCheckBox3>
                </div>

                <div className="flex justify-center gap-5 pt-3">
                  <div onClick={() => setUpdatePopup(true)}>
                    <CustomButton1 label={"Update"} className="text-white bg-prp-color" />
                  </div>
                  <div onClick={onCancel}>
                    <CustomButton2 label={"Cancel"} variant="outlined" className="txt-prp-color" />
                  </div>
                </div>
              </>
            )}

          </Accordion>
        </div>
      </div>

      {recordEdit && (
        <PopUp>
          <LCReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord}
            onUpdate={handleUpdateRecord}
            onCancel={() => {
              setRecordEdit(false)
            }}
          />
        </PopUp>
      )}

      {recordView && (
        <PopUp>
          <LCReceivedEditInView
            record={selectedRecord}
            onCancel={() => setRecordView(false)}
          />
        </PopUp>
      )}

      {recordEdit1 && (
        <PopUp>
          <PaymentReceivedEditInEdit
            selectedValue={selectedValue}
            record={selectedRecord1}
            onCancel={() => {
              setRecordEdit1(false)
            }}
          />
        </PopUp>
      )}

      {recordView1 && (
        <PopUp>
          <PaymentReceivedEditInView
            record={selectedRecord1}
            onCancel={() => setRecordView1(false)}
          />
        </PopUp>
      )}

      {updatePopup && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      {updatePopup1 && (
        <PopUp>
          <div className="pb-2 bg-white border rounded-lg">
            <div className="flex flex-col p-4">
              <p className="pb-5 text-sm">Are you sure you want to update ?</p>

              <div className="flex justify-end gap-2">
                <div onClick={handleSubmit1}>
                  <CustomButton1 label={"Yes"} className="text-white bg-prp-color" type="submit" />
                </div>
                <div onClick={() => setUpdatePopup1(false)}>
                  <CustomButton2 label={"No"} variant="outlined" className="txt-prp-color" />
                </div>
              </div>
            </div>
          </div>
        </PopUp>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="View File"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="coman-modal-img-end">
          <p>{fileName}</p>
          <button onClick={closeModal} className="text-gray-600 hover:text-gray-800">
            <MdCancel size={24} />
          </button>
        </div>
        {fileType === 'image/' && selectedFileUrl && (
          <img src={selectedFileUrl} alt="Enlarged View" className="w-full h-auto" />
        )}
        {fileType === 'application/pdf' && selectedFileUrl && (
          <embed src={selectedFileUrl} type="application/pdf" className="w-full h-96" />
        )}
      </Modal>

      <ToastContainer autoClose={2000} />
    </>
  )
}

